<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>个人赛考务管理</el-breadcrumb-item>
            <el-breadcrumb-item>成绩查询</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 信息筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">信息筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="100px"
                ref="queryFormRef"
            >
                <el-row :gutter="1">
                    <el-col :span="6">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择赛季"
                                style="width: 100%"
                                @change="seasonHandleChange"
                                v-model="queryForm.season"
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="所属赛区" prop="districtId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.districtId"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="赛程" prop="raceSchedule">
                            <el-select
                                placeholder="请选择"
                                @visible-change="changeRaceScheduleValue"
                                @change="changeRaceScheduleData"
                                style="width: 100%"
                                v-model="queryForm.raceSchedule"
                                clearable
                            >
                                <el-option
                                    v-for="item in raceScheduleList"
                                    :key="item.id"
                                    :label="item.scheduleName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="手机号" prop="phone">
                            <el-input
                                v-model="queryForm.phone"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="1">
                    <el-col :span="6">
                        <el-form-item label="学校省市区" prop="schoolArea">
                            <el-cascader
                                placeholder="请选择"
                                clearable
                                v-model="queryForm.schoolArea"
                                :options="areasData"
                                style="width: 100%"
                                :props="{
                                    expandTrigger: 'hover',
                                    label: 'name',
                                    value: 'areaId',
                                    children: 'areas',
                                }"
                                @change="provincesHandleChange"
                            ></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select
                                ref="schoolSelectRef"
                                style="width: 100%"
                                placeholder="请选择"
                                v-model="queryForm.schoolId"
                                clearable
                                filterable
                            >
                                <el-option
                                    v-for="item in provincesSchoolList"
                                    :key="item.id"
                                    :label="item.schoolName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="学生姓名" prop="userName">
                            <el-input
                                v-model="queryForm.userName"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="是否晋级" prop="promotion">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.promotion"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in isPromotion"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6" v-if="scoreTypeActiveName === 'off-line'">
                        <el-form-item label="是否匹配" prop="isMatch">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.isMatch"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in isMatch"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item
                            label="年级"
                            prop="grade"
                            v-if="scoreTypeActiveName === 'on-line'"
                        >
                            <el-select
                                v-model="queryForm.grade"
                                placeholder="请选择"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_grade"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                >
                                </el-option>
                            </el-select> </el-form-item
                    ></el-col>
                    <!-- <el-col :span="6">
                        <el-form-item
                            label="年级"
                            prop="scoreGrade"
                            v-if="scoreTypeActiveName === 'off-line'"
                        >
                            <el-select
                                v-model="queryForm.scoreGrade"
                                placeholder="请选择"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_grade"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                >
                                </el-option>
                            </el-select> </el-form-item
                    ></el-col> -->
                    <el-col :span="6" v-if="scoreTypeActiveName === 'off-line'">
                        <el-form-item label="成绩查询码" prop="scoreCode">
                            <el-input
                                v-model="queryForm.scoreCode"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="scoreTypeActiveName === 'on-line'">
                        <el-form-item label="组别" prop="enrollmentLevel">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.enrollmentLevel"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_Group"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="scheduleFlag === 'NATIONAL_FINALS_GROUP' || scheduleFlag === 'NATIONAL_FINALS_GROUP_PROMOTED'">
                        <el-form-item label="参加复活赛" prop="reviveRound">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.reviveRound"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    label="第1轮"
                                    :value="1"
                                ></el-option>
                                <el-option
                                    label="第2轮"
                                    :value="2"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col
                      :span="6"
                      v-if="
                          scheduleFlag !== 'PRIMARY_SELECTION' &&
                          scheduleFlag !== 'INDIVIDUAL_FINAL'
                      "
                  >
                      <el-form-item label="是否签到" prop="signIn">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              clearable
                              v-model="queryForm.signIn"
                          >
                              <el-option
                                  v-for="item in dict_state"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col> -->
                    <!-- <el-col
                      :span="6"
                      v-if="
                          scheduleFlag !== 'PRIMARY_SELECTION' &&
                          scheduleFlag !== 'INDIVIDUAL_FINAL'
                      "
                  >
                      <el-form-item label="查询签到" prop="seeSignUp">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              clearable
                              v-model="queryForm.seeSignUp"
                          >
                              <el-option
                                  v-for="item in dict_seeSignUp"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col> -->
                    <!-- <el-col :span="6">
                        <el-form-item label="开通类型" prop="openType">
                            <el-select
                                style="width: 100%"
                                placeholder="请选择"
                                v-model="queryForm.openType"
                                clearable
                            >
                                <el-option
                                    v-for="item in p_open_type"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                    <el-col
                        :span="6"
                        v-if="
                            scoreTypeActiveName === 'on-line' &&
                            scheduleFlag === 'PRIMARY_SELECTION'
                        "
                    >
                        <el-form-item label="资格" prop="isOnline">
                            <el-select
                                placeholder="请选择"
                                v-model="queryForm.isOnline"
                                style="width: 100%"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_isOnline"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="6"
                        v-if="
                            scheduleFlag === 'WEB_FINALS' ||
                            scheduleFlag === 'CITY_FINALS'
                        "
                    >
                        <el-form-item
                            label="国赛资格"
                            prop="ableApplyNationmatch"
                        >
                            <el-select
                                style="width: 100%"
                                placeholder="请选择"
                                v-model="queryForm.ableApplyNationmatch"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_qualification"
                                    :key="item.dictValue"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="6"
                        v-if="
                            scheduleFlag === 'WEB_FINALS' ||
                            scheduleFlag === 'CITY_FINALS'
                        "
                    >
                        <el-form-item
                            label="缴费状态"
                            prop="nationmatchIsPayed"
                        >
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.nationmatchIsPayed"
                                clearable
                            >
                                <el-option
                                    v-for="item in nationmatchIsPayedList"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="6"
                        v-if="
                            scheduleFlag === 'WEB_FINALS' ||
                            scheduleFlag === 'CITY_FINALS'
                        "
                    >
                        <el-form-item
                            label="是否弃赛"
                            prop="forgoStatus"
                        >
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.forgoStatus"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_forgoStatus"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :span="6"
                        v-if="
                            scheduleFlag === 'WEB_FINALS' ||
                            scheduleFlag === 'CITY_FINALS'
                        "
                    >
                        <el-form-item
                            label="国赛直通"
                            prop="nationalThrough"
                        >
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.nationalThrough"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_nationalThrough"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="6">
                        <el-form-item label="IBC选手" prop="ibcIdentity">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.ibcIdentity"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_countryReserve"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                </el-row>
            </el-form>
            <el-row :gutter="20" type="flex" justify="end">
                <el-col :span="2">
                    <el-button
                        type="primary"
                        style="width: 100%"
                        @click="getDataList(scoreTypeActiveName, '')"
                        >查询</el-button
                    >
                </el-col>
                <el-col :span="2">
                    <el-button
                        type="primary"
                        plain
                        style="width: 100%"
                        @click="reset"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </el-card>
        <!-- 初赛成绩信息列表 -->
        <el-card
            style="margin-top: 15px"
            v-if="scheduleFlag === 'PRIMARY_SELECTION'"
        >
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="4">
                    <span class="title_class">成绩信息列表</span>
                </el-col>
                <el-col :span="20">
                    <div style="text-align: right">
                        <!-- <el-button
                          type="primary"
                          @click="batchTransferDialogVisible = true"
                          >报名资格批量转移</el-button
                      > -->
                        <el-button
                            type="primary"
                            @click="addScoreDialogVisibleClicked"
                            v-if="scoreTypeActiveName === 'off-line'"
                            >单项新增成绩</el-button
                        >
                        <el-button
                            type="primary"
                            @click="importOffLineScoreClicked"
                            v-if="scoreTypeActiveName === 'off-line'"
                            >批量导入成绩</el-button
                        >
                        <el-button
                            type="primary"
                            v-if="scoreTypeActiveName === 'off-line'"
                            @click="scoreQueryCodeClicked"
                            >批量生成成绩查询码</el-button
                        >
                        <el-button
                            type="primary"
                            @click="setPassDialogVisible = true"
                            >批量设置晋级</el-button
                        >
                        <el-button
                            type="primary"
                            v-if="scoreTypeActiveName === 'on-line'"
                            @click="downLoadScoreSearchData"
                            >导出成绩</el-button
                        >
                        <el-button
                            v-if="scoreTypeActiveName === 'on-line'"
                            type="primary"
                            @click="importScoreClicked"
                            >批量导入成绩</el-button
                        >
                        <el-button
                            type="primary"
                            @click="downLoadScoreSearchCode"
                            v-if="scoreTypeActiveName === 'off-line'"
                            >导出成绩</el-button
                        >
                    </div>
                </el-col>
            </el-row>
            <el-tabs
                type="border-card"
                v-model="scoreTypeActiveName"
                @tab-click="scoreTypeChengeClick"
            >
                <el-tab-pane label="线上成绩" name="on-line">
                    <el-table
                        header-cell-class-name="tableHeaderStyle"
                        :data="dataList"
                        border
                        stripe
                    >
                        <el-table-column
                            label="序号"
                            type="index"
                        ></el-table-column>
                        <el-table-column label="姓名">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="checkDetail(scope.row)"
                                    >{{ scope.row.userName }}
                                    {{
                                        scope.row.countryReserve === true
                                            ? "(保留)"
                                            : ""
                                    }}</el-button
                                >
                            </template>
                        </el-table-column>
                        <!-- <el-table-column
                          label="姓名"
                          prop="userName"
                      ></el-table-column> -->
                        <el-table-column
                            label="手机号"
                            prop="phone"
                        ></el-table-column>
                        <el-table-column
                            label="学校"
                            prop="schoolName"
                        ></el-table-column>
                        <el-table-column
                            label="组别"
                            :formatter="groupFormatter"
                        ></el-table-column>
                        <el-table-column
                            label="年级"
                            prop="grade"
                        ></el-table-column>
                        <el-table-column
                            label="成绩"
                            prop="score"
                        ></el-table-column>
                        <el-table-column
                            label="答题次数"
                            prop="answerNum"
                        ></el-table-column>
                        <el-table-column label="晋级状态" prop="expectedTime">
                            <template slot-scope="scope">
                                <el-tag
                                    type="danger"
                                    v-if="scope.row.promotion === 2"
                                    size="mini"
                                    >未晋级</el-tag
                                >
                                <el-tag
                                    type="success"
                                    v-else-if="scope.row.promotion === 1"
                                    size="mini"
                                    >晋级</el-tag
                                >
                                <el-tag
                                    type="success"
                                    v-else-if="
                                        !scope.row.promotion ||
                                        scope.row.promotion === 0
                                    "
                                    size="warning"
                                    >未设置</el-tag
                                >
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="开通类型"
                            prop="openType"
                            :formatter="openTypeFormatter"
                            width="100px"
                        ></el-table-column>
                        <el-table-column label="操作" width="140px">
                            <template slot-scope="scope">
                                <div
                                    style="
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: space-around;
                                        padding: 5px 0;
                                    "
                                >
                                    <el-button
                                        type="text"
                                        icon="el-icon-edit"
                                        size="small"
                                        style="margin: 8px 0"
                                        @click="showEditDialog(scope.row)"
                                        >晋级修改</el-button
                                    >
                                    <el-button
                                        type="text"
                                        icon="el-icon-edit"
                                        size="small"
                                        style="margin: 8px 0"
                                        @click="showViewDetails(scope.row)"
                                        >查看详情</el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="线下成绩" name="off-line">
                    <el-table
                        header-cell-class-name="tableHeaderStyle"
                        :data="dataList"
                        border
                        stripe
                    >
                        <el-table-column
                            label="序号"
                            type="index"
                        ></el-table-column>
                        <el-table-column label="省市区" prop="provence">
                            <template slot-scope="scope">
                                <span
                                    >{{ scope.row.province }}-{{
                                        scope.row.city
                                    }}-{{ scope.row.area }}</span
                                >
                            </template>
                        </el-table-column>
                        <!-- <el-table-column
          label="性别"
          prop="scoreSex"
          width="50px"
        ></el-table-column> -->
                        <el-table-column label="试卷中信息">
                            <el-table-column
                                label="姓名"
                                prop="scoreName"
                            ></el-table-column>
                            <el-table-column
                                label="电话"
                                prop="scorePhoneOne"
                            ></el-table-column>
                            <!-- <el-table-column
            label="组别"
            :formatter="gradeGroupFormatter"
          ></el-table-column> -->
                            <el-table-column
                                label="学校"
                                prop="scoreSchoolName"
                            ></el-table-column>
                            <el-table-column
                                label="年级"
                                prop="scoreGrade"
                                :formatter="gradeFormatter"
                            ></el-table-column>
                            <el-table-column
                                label="班级"
                                prop="scoreClass"
                            ></el-table-column>
                        </el-table-column>
                        <el-table-column label="系统中信息">
                            <el-table-column label="姓名">
                                <template slot-scope="scope">
                                    <el-button
                                        v-if="scope.row.userName"
                                        type="text"
                                        size="small"
                                        @click="checkDetail(scope.row)"
                                        >{{ scope.row.userName }}
                                    </el-button>
                                    <span v-else>{{ "-" }}</span>
                                </template>
                            </el-table-column>
                            <!-- <el-table-column label="姓名" prop="userName">
                              <template slot-scope="{ row }">
                                  {{ row.userName || "-" }}
                              </template>
                          </el-table-column> -->
                            <el-table-column
                                label="电话"
                                prop="phone"
                                width="100px"
                            >
                                <template slot-scope="{ row }">
                                    {{ row.phone || "-" }}
                                </template>
                            </el-table-column>
                            <el-table-column label="学校" prop="userSchoolName">
                                <template slot-scope="{ row }">
                                    {{ row.userSchoolName || "-" }}
                                </template>
                            </el-table-column>
                            <el-table-column label="年级" prop="grade">
                                <template slot-scope="{ row }">
                                    {{ row.grade || "-" }}
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column
                            label="成绩"
                            prop="scores"
                        ></el-table-column>
                        <el-table-column
                            label="开通类型"
                            prop="openType"
                            :formatter="openTypeFormatter"
                            width="100px"
                        ></el-table-column>
                        <el-table-column
                            label="成绩查询码"
                            prop="scoreCode"
                        ></el-table-column>
                        <el-table-column label="晋级状态" prop="scorePromotion">
                            <template slot-scope="scope">
                                <el-tag
                                    type="danger"
                                    v-if="scope.row.scorePromotion === 2"
                                    size="mini"
                                    >未晋级</el-tag
                                >
                                <el-tag
                                    type="success"
                                    v-else-if="scope.row.scorePromotion === 1"
                                    size="mini"
                                    >晋级</el-tag
                                >
                                <el-tag
                                    type="success"
                                    v-else-if="
                                        !scope.row.scorePromotion ||
                                        scope.row.scorePromotion === 0
                                    "
                                    size="warning"
                                    >未设置</el-tag
                                >
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="匹配状态" prop="scorePromotion">
                            <template slot-scope="scope">
                                <el-tag
                                    type="danger"
                                    v-if="scope.row.userId == null"
                                    size="mini"
                                    >未匹配</el-tag
                                >
                                <el-tag
                                    type="success"
                                    v-else-if="scope.row.userId != null"
                                    size="mini"
                                    >已匹配</el-tag
                                >
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="180px">
                            <template slot-scope="scope">
                                <div
                                    style="
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: space-around;
                                        padding: 5px 0;
                                    "
                                >
                                    <el-button
                                        v-if="
                                            scheduleFlag === 'CITY_FINALS' ||
                                            scheduleFlag === 'WEB_FINALS' ||
                                            scheduleFlag === 'CITY_HALF' ||
                                            scheduleFlag === 'NATIONAL_FINALS_GROUP' ||
                                            scheduleFlag === 'NATIONAL_FINALS_GROUP_PROMOTED'
                                        "
                                        type="text"
                                        icon="el-icon-edit"
                                        size="small"
                                        style="margin: 8px 0"
                                        class="blue-text-btn"
                                        @click="showEditDialog(scope.row)"
                                        ><span style="font-size: 18px"
                                            >晋级修改</span
                                        ></el-button
                                    >
                                    <el-button
                                        v-if="
                                            scheduleFlag === 'CITY_FINALS' ||
                                            scheduleFlag === 'WEB_FINALS'
                                        "
                                        type="text"
                                        icon="el-icon-edit"
                                        size="small"
                                        style="margin: 8px 0"
                                        class="green-text-btn"
                                        @click="
                                            showQualificationEditDialog(
                                                scope.row
                                            )
                                        "
                                        ><span style="font-size: 18px"
                                            >资格修改</span
                                        ></el-button
                                    >
                                    <el-button
                                        type="text"
                                        icon="el-icon-edit"
                                        size="small"
                                        style="margin: 8px 0"
                                        class="orange-text-btn"
                                        v-if="
                                            scheduleFlag === 'CITY_FINALS' ||
                                            scheduleFlag === 'WEB_FINALS'
                                        "
                                        @click="getPaycostDetail(scope.row)"
                                        ><span style="font-size: 18px"
                                            >审核缴费</span
                                        ></el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 复赛成绩信息列表 -->
        <el-card
            style="margin-top: 15px"
            v-if="scheduleFlag === 'INDIVIDUAL_FINAL'"
        >
            <el-row class="title_row" align="middle" type="flex">
                <el-col :span="4">
                    <span class="title_class">成绩信息列表</span>
                </el-col>
                <el-col :span="20">
                    <div style="text-align: right">
                        <!-- <el-button
                          type="primary"
                          @click="batchTransferDialogVisible = true"
                          >报名资格批量转移</el-button
                      > -->
                        <el-button
                            type="primary"
                            @click="setPassDialogVisible = true"
                            >批量设置晋级</el-button
                        >
                        <el-button
                            type="primary"
                            @click="downLoadScoreSearchData"
                            >导出成绩</el-button
                        >
                        <el-button type="primary" @click="importScoreClicked"
                            >批量导入成绩</el-button
                        >
                    </div>
                </el-col>
            </el-row>
            <el-tabs
                type="border-card"
                v-model="scoreTypeActiveName"
                @tab-click="scoreTypeChengeClick"
            >
                <el-tab-pane label="线上资格" name="on-line">
                    <el-table
                        header-cell-class-name="tableHeaderStyle"
                        :data="dataList"
                        key="INDIVIDUAL_FINAL_ON"
                        border
                        stripe
                    >
                        <el-table-column
                            label="序号"
                            type="index"
                        ></el-table-column>
                        <el-table-column label="姓名">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="checkDetail(scope.row)"
                                    >{{ scope.row.userName }}
                                    {{
                                        scope.row.countryReserve === true
                                            ? "(保留)"
                                            : ""
                                    }}</el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="手机号"
                            prop="phone"
                        ></el-table-column>
                        <el-table-column
                            label="学校"
                            prop="schoolName"
                        ></el-table-column>
                        <el-table-column
                            label="组别"
                            :formatter="groupFormatter"
                        ></el-table-column>
                        <el-table-column
                            label="年级"
                            prop="grade"
                        ></el-table-column>
                        <el-table-column
                            label="成绩"
                            prop="score"
                        ></el-table-column>
                        <el-table-column
                            label="开通类型"
                            prop="openType"
                            :formatter="openTypeFormatter"
                            width="100px"
                        ></el-table-column>
                        <el-table-column
                            label="答题次数"
                            prop="answerNum"
                        ></el-table-column>
                        <el-table-column label="晋级状态" prop="expectedTime">
                            <template slot-scope="scope">
                                <el-tag
                                    type="danger"
                                    v-if="scope.row.promotion === 2"
                                    size="mini"
                                    >未晋级</el-tag
                                >
                                <el-tag
                                    type="success"
                                    v-else-if="scope.row.promotion === 1"
                                    size="mini"
                                    >晋级</el-tag
                                >
                                <el-tag
                                    type="success"
                                    v-else-if="
                                        !scope.row.promotion ||
                                        scope.row.promotion === 0
                                    "
                                    size="warning"
                                    >未设置</el-tag
                                >
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="140px">
                            <template slot-scope="scope">
                                <div
                                    style="
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: space-around;
                                        padding: 5px 0;
                                    "
                                >
                                    <el-button
                                        type="text"
                                        icon="el-icon-edit"
                                        size="small"
                                        style="margin: 8px 0"
                                        @click="showEditDialog(scope.row)"
                                        >晋级修改</el-button
                                    >
                                    <el-button
                                        type="text"
                                        icon="el-icon-edit"
                                        size="small"
                                        style="margin: 8px 0"
                                        @click="showViewDetails(scope.row)"
                                        >查看详情</el-button
                                    >
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="线下资格" name="off-line">
                    <el-table
                        header-cell-class-name="tableHeaderStyle"
                        :data="dataList"
                        key="INDIVIDUAL_FINAL_OFF"
                        border
                        stripe
                    >
                        <el-table-column
                            label="序号"
                            type="index"
                        ></el-table-column>

                        <el-table-column label="姓名">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="checkDetail(scope.row)"
                                    >{{ scope.row.userName }}
                                    {{
                                        scope.row.countryReserve === true
                                            ? "(保留)"
                                            : ""
                                    }}</el-button
                                >
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="手机号"
                            prop="phone"
                        ></el-table-column>
                        <el-table-column
                            label="学校"
                            prop="schoolName"
                        ></el-table-column>
                        <el-table-column
                            label="组别"
                            :formatter="groupFormatter"
                        ></el-table-column>
                        <el-table-column
                            label="年级"
                            prop="grade"
                        ></el-table-column>
                        <el-table-column
                            label="成绩"
                            prop="score"
                        ></el-table-column>
                        <el-table-column
                            label="开通类型"
                            prop="openType"
                            :formatter="openTypeFormatter"
                            width="100px"
                        ></el-table-column>
                        <el-table-column label="晋级状态" prop="promotion">
                            <template slot-scope="scope">
                                <el-tag
                                    type="danger"
                                    v-if="scope.row.promotion === 2"
                                    size="mini"
                                    >未晋级</el-tag
                                >
                                <el-tag
                                    type="success"
                                    v-else-if="scope.row.promotion === 1"
                                    size="mini"
                                    >晋级</el-tag
                                >
                                <el-tag
                                    type="success"
                                    v-else-if="
                                        !scope.row.promotion ||
                                        scope.row.promotion === 0
                                    "
                                    size="warning"
                                    >未设置</el-tag
                                >
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="90px">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    icon="el-icon-edit"
                                    size="small"
                                    @click="showEditDialog(scope.row)"
                                    >晋级修改</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 城市赛/国赛成绩信息列表 -->
        <el-card
            style="margin-top: 15px"
            v-if="
                scheduleFlag !== 'PRIMARY_SELECTION' &&
                scheduleFlag !== 'INDIVIDUAL_FINAL'
            "
        >
            <el-row class="title_row">
                <el-col :span="12">
                    <span>成绩信息列表</span>
                </el-col>
                <el-col :span="12">
                    <div style="text-align: right">
                        <!-- <el-button
                          type="primary"
                          @click="batchTransferDialogVisible = true"
                          >报名资格批量转移</el-button
                      > -->
                        <el-button
                            type="primary"
                            @click="downLoadScoreSearchData"
                            >导出成绩</el-button
                        >
                        <el-button type="primary" @click="importScoreClicked"
                            >批量导入成绩</el-button
                        >
                    </div>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column
                    width="80"
                    label="赛区"
                    prop="districtName"
                ></el-table-column>
                <el-table-column label="信息" width="150">
                    <template slot-scope="scope">
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                            "
                        >
                            <div>
                                <el-link
                                    style="color: #409eff; text-align: center"
                                    @click="checkDetail(scope.row)"
                                    >{{ scope.row.userName }}
                                </el-link>
                                {{
                                    scope.row.nationalReserve === true
                                        ? "(国赛保留)"
                                        : ""
                                }}
                                {{
                                    scope.row.ibcIdentity === true
                                        ? "(IBC选手)"
                                        : ""
                                }}
                                <!-- 添加弃赛和国赛直通的判断 -->
                                <span v-if="scope.row.forgoStatus === true" style="color: red">(已弃赛)</span>
                                <span v-if="scope.row.nationalThrough === true" style="color: green">(国赛直通)</span>
                            </div>
                            <div>
                                {{ scope.row.phone }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                  label="手机号"
                  prop="phone"
                  width="100px"
              ></el-table-column> -->
                <el-table-column
                    v-if="
                        scheduleFlag === 'NATIONAL_FINALS_GROUP' ||
                        scheduleFlag === 'NATIONAL_FINALS_GROUP_PROMOTED' ||
                        scheduleFlag === 'NATIONAL_FINALS'
                    "
                    label="比赛号码牌"
                    prop="nationalnum"
                    width="90"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div class="nationalnum" style="color: red">
                            {{
                                scope.row.nationalnum
                                    ? scope.row.nationalnum
                                    : "-"
                            }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    v-if="
                        scheduleFlag === 'CITY_FINALS' ||
                        scheduleFlag === 'WEB_FINALS' ||
                        scheduleFlag === 'CITY_HALF'
                    "
                    label="比赛号码牌"
                    width="90"
                    prop="competitionNumber"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div class="nationalnum" style="color: red">
                            {{
                                scope.row.competitionNumber
                                    ? scope.row.competitionNumber
                                    : "-"
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="名次" prop="promotionRank" width="60"
                    ><template slot-scope="scope">
                        <span type="success" v-if="scope.row.promotionRank">{{
                            scope.row.promotionRank
                        }}</span>
                        <span v-else type="danger" size="mini">暂无</span>
                    </template>
                </el-table-column>
                <el-table-column label="参加复活赛" align="center" width="100px" prop="reviveRound"
                    v-if="scheduleFlag === 'NATIONAL_FINALS_GROUP' ||
                        scheduleFlag === 'NATIONAL_FINALS_GROUP_PROMOTED'"
                    ><template slot-scope="scope">
                        <template v-if="scope.row.reviveRound">
                            第<span class="round">{{ scope.row.reviveRound }}</span>轮
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="学校" prop="schoolName" min-width="100">
                    <template slot-scope="scope">
                        <div style="display: flex; flex-direction: column">
                            <div>{{ scope.row.schoolName }}</div>
                            <div style="color: green">
                                {{ scope.row.grade }}年级
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                  label="组别"
                  :formatter="groupFormatter"
              ></el-table-column> -->
                <!-- <el-table-column label="年级" prop="grade"></el-table-column> -->
                <el-table-column
                    label="成绩"
                    prop="score"
                    v-if="scheduleFlag === 'WEB_FINALS'"
                ></el-table-column>
                <el-table-column label="晋级状态" width="90">
                    <template slot-scope="scope">
                        <el-tag
                            type="danger"
                            v-if="scope.row.promotion === 2"
                            size="mini"
                            >未晋级</el-tag
                        >
                        <el-tag
                            type="success"
                            size="mini"
                            v-else-if="scope.row.promotion === 1"
                            >晋级</el-tag
                        >
                        <el-tag v-else type="warning" size="mini"
                            >未设置</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    label="全国总决选缴费项目"
                    prop="paymentDetails"
                    v-if="
                        scheduleFlag === 'CITY_FINALS' ||
                        scheduleFlag === 'WEB_FINALS'
                    "
                    :show-overflow-tooltip="true"
                    width="130px"
                ></el-table-column>
                <el-table-column
                    label="国赛资格"
                    fixed="right"
                    width="90"
                    v-if="
                        scheduleFlag === 'CITY_FINALS' ||
                        scheduleFlag === 'WEB_FINALS'
                    "
                >
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.ableApplyNationmatch"
                            size="mini"
                            >有资格</el-tag
                        >
                        <el-tag v-else type="danger" size="mini">无资格</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="国赛缴费状态"
                    fixed="right"
                    width="100"
                    v-if="
                        scheduleFlag === 'CITY_FINALS' ||
                        scheduleFlag === 'WEB_FINALS'
                    "
                >
                    <template slot-scope="scope">
                        <el-tag
                            type="danger"
                            v-if="scope.row.nationmatchIsPayed == 0"
                            size="mini"
                            >未提交</el-tag
                        >
                        <el-tag
                            type="warning"
                            size="mini"
                            v-else-if="scope.row.nationmatchIsPayed == 1"
                            >待审核</el-tag
                        >
                        <el-tag
                            type="warning"
                            size="mini"
                            v-else-if="scope.row.nationmatchIsPayed == 3"
                            >已审核</el-tag
                        >
                        <el-tag
                            type="success"
                            size="mini"
                            v-else-if="scope.row.nationmatchIsPayed == 2"
                            >已缴费</el-tag
                        >
                        <el-tag
                            type="error"
                            size="mini"
                            v-else-if="scope.row.nationmatchIsPayed == -1"
                            >已驳回</el-tag
                        >
                        <!-- <el-tag
                          type="warning"
                          v-if="scope.row.status == 1"
                          size="mini"
                          >待确认缴费</el-tag
                      >
                      <el-tag
                          type="warning"
                          size="mini"
                          v-else-if="scope.row.status == 2"
                          >待审核</el-tag
                      >
                      <el-tag
                          type="warning"
                          size="mini"
                          v-else-if="scope.row.status == 3"
                          >已审核</el-tag
                      >
                      <el-tag
                          type="success"
                          size="mini"
                          v-else-if="scope.row.status == 4"
                          >已支付</el-tag
                      >
                      <el-tag
                          type="error"
                          size="mini"
                          v-else-if="scope.row.status == -1"
                          >已驳回</el-tag
                      >
                      <el-tag v-else type="danger" size="mini">未缴费</el-tag> -->
                    </template>
                </el-table-column>
                <el-table-column
                    label="缴费金额"
                    width="120"
                    fixed="right"
                    prop="nationmatchPayedPrice"
                    v-if="
                        scheduleFlag === 'CITY_FINALS' ||
                        scheduleFlag === 'WEB_FINALS'
                    "
                >
                    <template slot-scope="scope">
                        <div class="nationalnum">
                            {{
                                scope.row.nationmatchPayedPrice
                                    ? scope.row.nationmatchPayedPrice
                                    : "0.00"
                            }}
                        </div>
                    </template></el-table-column
                >
                <el-table-column
                    label="名次"
                    prop="promotionRank"
                    v-if="scheduleFlag === 'NATIONAL_FINALS'"
                ></el-table-column>
                <el-table-column
                    label="操作"
                    fixed="right"
                    width="120px"
                    v-if="scheduleFlag !== 'NATIONAL_FINALS'"
                >
                    <template slot-scope="scope">
                        <div
                            style="
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-around;
                            "
                        >
                            <el-button
                                v-if="
                                    scheduleFlag === 'CITY_FINALS' ||
                                    scheduleFlag === 'WEB_FINALS' ||
                                    scheduleFlag === 'CITY_HALF' ||
                                    scheduleFlag === 'NATIONAL_FINALS_GROUP' ||
                                    scheduleFlag === 'NATIONAL_FINALS_GROUP_PROMOTED'
                                "
                                type="text"
                                icon="el-icon-edit"
                                size="small"
                                class="blue-text-btn"
                                @click="showEditDialog(scope.row)"
                                ><span style="font-size: 14px"
                                    >晋级修改</span
                                ></el-button
                            >
                            <el-button
                                v-if="
                                    scheduleFlag === 'CITY_FINALS' ||
                                    scheduleFlag === 'WEB_FINALS'
                                "
                                type="text"
                                icon="el-icon-edit"
                                size="small"
                                class="green-text-btn"
                                style="margin-left: 0px; margin-top: 10px"
                                @click="showQualificationEditDialog(scope.row)"
                                ><span style="font-size: 14px"
                                    >资格修改</span
                                ></el-button
                            >
                            <el-button
                                type="text"
                                icon="el-icon-edit"
                                size="small"
                                class="orange-text-btn"
                                style="margin-left: 0px; margin-top: 10px"
                                v-if="
                                    scheduleFlag === 'CITY_FINALS' ||
                                    scheduleFlag === 'WEB_FINALS' ||
                                    scheduleFlag === 'NATIONAL_FINALS_GROUP' ||
                                    scheduleFlag === 'NATIONAL_FINALS_GROUP_PROMOTED'
                                "
                                @click="getPaycostDetail(scope.row)"
                                ><span style="font-size: 14px"
                                    >审核缴费</span
                                ></el-button
                            >
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 修改线上成绩的dialog -->
        <el-dialog
            title="修改信息"
            :visible.sync="editScoreDialogVisible"
            @close="editScoreDialogClose"
            width="30%"
        >
            <el-form
                :model="editScoreForm"
                label-width="80px"
                ref="editScoreFormRef"
                :rules="editScoreFormRules"
            >
                <el-form-item label="姓名" prop="userName">
                    <el-input
                        v-model="editScoreForm.userName"
                        placeholder="请输入"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label="
                        editScoreForm.editType === 'qualification'
                            ? '报名资格'
                            : '晋级资格'
                    "
                    :prop="
                        editScoreForm.editType === 'qualification'
                            ? 'ableApplyNationmatch'
                            : 'promotion'
                    "
                >
                    <el-select
                        v-if="editScoreForm.editType === 'qualification'"
                        style="width: 100%"
                        placeholder="请选择"
                        v-model="editScoreForm.ableApplyNationmatch"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_qualification"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                    <el-select
                        v-else
                        placeholder="请选择"
                        v-model="editScoreForm.promotion"
                        style="width: 100%"
                        clearable
                    >
                        <el-option
                            v-for="item in isPromotion"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="editScoreForm.editType === 'qualification'"
                    label="是否弃赛"
                    prop="forgoStatus"
                >
                    <el-select
                        placeholder="请选择"
                        v-model="editScoreForm.forgoStatus"
                        style="width: 100%"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_forgoStatus"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="editScoreForm.editType === 'qualification'"
                    label="国赛直通"
                    prop="nationalThrough"
                >
                    <el-select
                        placeholder="请选择"
                        v-model="editScoreForm.nationalThrough"
                        style="width: 100%"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_nationalThrough"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editScoreDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editScore">修 改</el-button>
            </span>
        </el-dialog>
        <!-- 修改线下成绩的dialog -->
        <el-dialog
            title="修改成绩信息"
            :visible.sync="editofflineScoreDialogVisible"
            @close="editoffLineScoreDialogClose"
            width="30%"
        >
            <el-form
                :model="editOffScoreForm"
                label-width="80px"
                ref="editOffScoreFormRef"
                :rules="editScoreFormRules"
            >
                <el-form-item label="姓名" prop="scoreName">
                    <el-input
                        v-model="editOffScoreForm.scoreName"
                        placeholder="请输入"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="晋级资格" prop="scorePromotion">
                    <el-radio-group v-model="editOffScoreForm.scorePromotion">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editofflineScoreDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editOffScore"
                    >修 改</el-button
                >
            </span>
        </el-dialog>
        <!-- 批量导入线上成绩 -->
        <el-dialog
            title="批量导入成绩"
            :visible.sync="importOnlineScoreDialogVisible"
            @close="importScoreDialogClose"
            width="30%"
        >
            <span
                v-if="
                    scoreTypeActiveName === 'off-line' ||
                    queryForm.raceSchedule === '6'
                "
                style="color: red"
                >注意*导入文件请选择「导出成绩」的文件</span
            >
            <span slot="footer" class="dialog-footer">
                <el-upload
                    :action="scoreImportInfo.uploadUrl"
                    ref="onLoadScoreImportInfoRef"
                    :data="{}"
                    :headers="scoreImportInfo.headerObj"
                    :on-progress="scoreImportInfoProgress"
                    :on-success="scoreImportInfoSuccess"
                    :on-error="scoreImportInfoError"
                    :on-exceed="scoreImportInfoExceed"
                    :limit="1"
                    :auto-upload="false"
                    :on-change="onChangeLoad"
                >
                    <el-button slot="trigger" size="small" type="primary"
                        >选取文件</el-button
                    >
                    <el-button
                        style="margin-left: 10px"
                        type="primary"
                        size="small"
                        @click="beginUpLoadData"
                        >确认上传</el-button
                    >
                </el-upload>
            </span>
        </el-dialog>
        <!-- 批量导入线下成绩 -->
        <el-dialog
            title="批量导入线下成绩"
            :visible.sync="importScoreDialogVisible"
            @close="importScoreDialogClose"
            width="30%"
        >
            <el-form
                :model="importQueryForm"
                label-width="100px"
                ref="editInfoFormRef"
                :rules="importScoreInfoRules"
                style="width: 100%"
            >
                <el-form-item label="学校省市区" prop="schoolArea">
                    <el-cascader
                        placeholder="请选择"
                        v-model="importQueryForm.schoolArea"
                        :options="areasData"
                        style="width: 100%"
                        :props="{
                            expandTrigger: 'hover',
                            label: 'name',
                            value: 'areaId',
                            children: 'areas',
                        }"
                        @change="provincesHandleChange"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="学校名称" prop="schoolId">
                    <el-select
                        ref="schoolSelectRef"
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="importQueryForm.schoolId"
                        @change="selectSchoolChange"
                    >
                        <el-option
                            v-for="item in provincesSchoolList"
                            :key="item.id"
                            :label="item.schoolName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <span style="color: red"
                    >注*最大限制1000条上传数量！请分批上传</span
                >
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-upload
                    :action="scoreImportInfo.uploadUrl"
                    ref="scoreImportInfoRef"
                    :data="{
                        season: this.queryForm.season,
                        activityType: this.$chnEngStatusCode.activityType,
                        raceSchedule: this.queryForm.raceSchedule,
                        schoolId: this.importQueryForm.schoolId,
                        schoolName: this.importQueryForm.schoolName,
                    }"
                    :headers="scoreImportInfo.headerObj"
                    :on-progress="scoreImportInfoProgress"
                    :on-success="scoreImportInfoSuccess"
                    :on-error="scoreImportInfoError"
                    :on-exceed="scoreImportInfoExceed"
                    :limit="1"
                    :auto-upload="false"
                    :on-change="onChangeLoad"
                >
                    <el-button slot="trigger" size="small" type="primary"
                        >选取文件</el-button
                    >
                    <el-button
                        style="margin-left: 10px"
                        type="primary"
                        size="small"
                        @click="downLoadscoreImportInfoStencil"
                        >下载模版</el-button
                    >
                    <el-button
                        style="margin-left: 10px"
                        size="small"
                        type="success"
                        @click="submitscoreImportInfo"
                        :loading="isUploadFinish"
                        >新增报名信息</el-button
                    >
                    <el-button
                        style="margin-left: 10px"
                        size="small"
                        type="danger"
                        @click="coverscoreImportInfo"
                        :loading="isCoverUploadFinish"
                        >覆盖报名信息</el-button
                    >
                </el-upload>
            </span>
        </el-dialog>
        <!-- 批量设置晋级dialog -->
        <el-dialog
            title="批量设置晋级"
            :visible.sync="setPassDialogVisible"
            width="30%"
            @close="setPassDialogClose"
        >
            <el-form
                ref="setPassFormRef"
                :model="setPassForm"
                :rules="setPassFormRules"
                label-width="120px"
            >
                <el-form-item label="设置范围" prop="setRange">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="setPassForm.setRange"
                    >
                        <el-option
                            v-for="item in dict_setPassRange"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="赛区名称"
                    prop="zoneId"
                    v-if="setPassForm.setRange === 0"
                >
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="setPassForm.zoneId"
                    >
                        <el-option
                            v-for="item in dict_district"
                            :key="item.id"
                            :label="item.districtName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="学校地区"
                    prop="schoolArea"
                    v-if="setPassForm.setRange === 1"
                >
                    <el-cascader
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="setPassForm.schoolArea"
                        :options="areasData"
                        :props="{
                            expandTrigger: 'hover',
                            label: 'name',
                            value: 'areaId',
                            children: 'areas',
                        }"
                        @change="provincesHandleChange"
                    ></el-cascader>
                </el-form-item>
                <el-form-item
                    label="学校名称"
                    prop="schoolId"
                    v-if="setPassForm.setRange === 1"
                >
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="setPassForm.schoolId"
                    >
                        <el-option
                            v-for="item in provincesSchoolList"
                            :key="item.id"
                            :label="item.schoolName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="晋级方式" prop="wayOfPromotion">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="setPassForm.wayOfPromotion"
                    >
                        <el-option
                            v-for="item in dict_setPassWay"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设置值" prop="value">
                    <el-input
                        v-model="setPassForm.value"
                        placeholder="请输入"
                        type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item label="参赛人数" prop="numberCompetition">
                    <el-input
                        v-model="setPassForm.numberCompetition"
                        placeholder="请点击查看晋级预期"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="预期晋级人数" prop="expectNumber">
                    <el-input
                        v-model="setPassForm.expectNumber"
                        placeholder="请点击查看晋级预期"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="预期晋级分数线"
                    prop="expectLine"
                    v-if="setPassForm.wayOfPromotion === 1"
                >
                    <el-input
                        v-model="setPassForm.expectLine"
                        placeholder="请点击查看晋级预期"
                        disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="预期晋级比例" prop="promotionRatio">
                    <el-input
                        v-model="setPassForm.promotionRatio"
                        placeholder="请点击查看晋级预期"
                        disabled
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setPassDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="warning" @click="queryPromotionExpectations"
                    >查看晋级预期</el-button
                >
                <el-button type="primary" @click="setPass()">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择生成code方式的dialog -->
        <el-dialog
            title="选择生成方式"
            :visible.sync="scoreQueryCodeDialogVisible"
            @close="scoreQueryCodeDialogClose"
            width="35%"
        >
            <el-form
                :model="scoreQueryForm"
                :rules="scoreQueryFormRules"
                class="filter_Form"
                label-width="100px"
                ref="scoreQueryFormRef"
            >
                <el-form-item label="赛季" prop="season">
                    <el-select
                        placeholder="请选择赛季"
                        style="width: 100%"
                        v-model="scoreQueryForm.season"
                    >
                        <el-option
                            v-for="item in dict_season"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学校省市区" prop="schoolArea">
                    <el-cascader
                        placeholder="请选择"
                        v-model="scoreQueryForm.schoolArea"
                        :options="areasData"
                        style="width: 100%"
                        :props="{
                            expandTrigger: 'hover',
                            label: 'name',
                            value: 'areaId',
                            children: 'areas',
                        }"
                        @change="provincesHandleChange"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="学校" prop="schoolId">
                    <el-select
                        style="width: 100%"
                        placeholder="请选择"
                        v-model="scoreQueryForm.schoolId"
                        clearable
                        filterable
                    >
                        <el-option
                            v-for="item in provincesSchoolList"
                            :key="item.id"
                            :label="item.schoolName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="scoreQueryCodeDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="generateScoreQueryCode"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 新增成绩的dialog -->
        <el-dialog
            title="新增成绩信息"
            :visible.sync="addScoreDialogVisible"
            width="30%"
            @close="addScoreDialogClose"
        >
            <el-form
                :model="addScoreForm"
                label-width="80px"
                ref="addScoreFormRef"
                :rules="scoreFormRules"
            >
                <el-form-item label="手机号" prop="scorePhoneOne">
                    <el-input
                        v-model="addScoreForm.scorePhoneOne"
                        placeholder="请输入"
                    ></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="scoreName">
                    <el-input
                        v-model="addScoreForm.scoreName"
                        placeholder="请输入"
                    ></el-input>
                </el-form-item>
                <el-form-item label="学校地区" prop="schoolArea">
                    <el-cascader
                        placeholder="请选择"
                        v-model="addScoreForm.schoolArea"
                        :options="areasData"
                        style="width: 100%"
                        :props="{
                            expandTrigger: 'hover',
                            label: 'name',
                            value: 'areaId',
                            children: 'areas',
                        }"
                        @change="provincesHandleChange"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="学校名称" prop="schoolId">
                    <el-select
                        ref="addScoreSchoolSelectRef"
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="addScoreForm.schoolId"
                        @change="addSelectSchoolChange"
                        filterable
                    >
                        <el-option
                            v-for="item in provincesSchoolList"
                            :key="item.id"
                            :label="item.schoolName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="性别" prop="scoreSex">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="addScoreForm.scoreSex"
                    >
                        <el-option
                            v-for="item in dict_sex"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="年级" prop="scoreGrade">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="addScoreForm.scoreGrade"
                    >
                        <el-option
                            v-for="item in dict_grade"
                            :key="item.dictCode"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="班级" prop="scoreClass">
                    <el-input
                        v-model="addScoreForm.scoreClass"
                        placeholder="请输入"
                        type="number"
                    ></el-input>
                </el-form-item>
                <el-form-item label="赛程" prop="raceSchedule">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="addScoreForm.raceSchedule"
                        disable
                        clearable
                    >
                        <el-option
                            v-for="item in raceScheduleList"
                            :key="item.id"
                            :label="item.scheduleName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="成绩" prop="scores">
                    <el-input-number
                        v-model="addScoreForm.scores"
                        placeholder="请输入"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="晋级状态" prop="scorePromotion">
                    <el-radio-group v-model="addScoreForm.scorePromotion">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addScoreDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addScore">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 查看缴费信息dialog -->
        <el-dialog
            title="国赛报名缴费信息"
            :visible.sync="paymentInformationDialogVisible"
            width="50%"
        >
            <div label-width="80px">
                <!-- 基本信息 -->
                <el-card style="margin-top: 15px">
                    <el-row :gutter="20" class="title_row">
                        <el-col :span="10">
                            <span class="exam_span_class Title">基本信息</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24" class="el-row">
                        <el-col :span="6">
                            <span class="exam_span_class"
                                >姓名：{{
                                    this.PayCostDetail.userInfo.userName
                                }}</span
                            >
                        </el-col>
                        <el-col :span="6">
                            <span class="exam_span_class"
                                >性别：{{
                                    this.PayCostDetail.userInfo.sex === "1"
                                        ? "男"
                                        : "女"
                                }}</span
                            >
                        </el-col>
                        <el-col :span="6">
                            <span class="exam_span_class"
                                >国籍：{{
                                    this.PayCostDetail.userInfo.nationality
                                        ? this.PayCostDetail.userInfo
                                              .nationality
                                        : "未填写"
                                }}</span
                            >
                        </el-col>
                        <el-col :span="6">
                            <span class="exam_span_class"
                                >城市：{{
                                    this.PayCostDetail.userInfo.city
                                }}</span
                            >
                        </el-col>
                    </el-row>
                    <el-row :gutter="24" class="el-row">
                        <el-col :span="6">
                            <span
                                class="exam_span_class"
                                v-if="
                                    this.PayCostDetail.userInfo.grade === 1 ||
                                    this.PayCostDetail.userInfo.grade === 2
                                "
                                >组别：小初组</span
                            >
                            <span
                                class="exam_span_class"
                                v-else-if="
                                    this.PayCostDetail.userInfo.grade === 4 ||
                                    this.PayCostDetail.userInfo.grade === 3
                                "
                                >组别：小中组</span
                            >
                            <span
                                class="exam_span_class"
                                v-else-if="
                                    this.PayCostDetail.userInfo.grade === 5 ||
                                    this.PayCostDetail.userInfo.grade === 6
                                "
                                >组别：小高组</span
                            >
                            <span
                                class="exam_span_class"
                                v-else-if="
                                    this.PayCostDetail.userInfo.grade === 7 ||
                                    this.PayCostDetail.userInfo.grade === 8 ||
                                    this.PayCostDetail.userInfo.grade === 9
                                "
                                >组别：初中组</span
                            >
                            <span class="exam_span_class" v-else>未知</span>
                        </el-col>
                        <el-col :span="10">
                            <span class="exam_span_class"
                                >学校：{{
                                    PayCostDetail.userInfo.schoolName
                                }}</span
                            >
                        </el-col>
                        <el-col :span="5" v-if="PayCostDetail.userInfo.rank">
                            <span class="exam_span_class"
                                >城市总决赛名次：{{
                                    PayCostDetail.userInfo.rank
                                }}</span
                            >
                        </el-col>
                    </el-row>
                </el-card>
                <!-- 缴费项目 -->
                <el-card
                    style="margin-top: 15px"
                    v-if="PayCostDetail.interestsDetailList"
                >
                    <!-- <el-row :gutter="20" class="title_row"> -->
                    <!-- <el-col :span="10"> -->
                    <div class="topmenu-backview">
                        <span class="exam_span_class Title">缴费项目</span>
                        <el-button
                            type="primary"
                            class="change_paycost_btn"
                            @click="changePaycostClicked"
                            >缴费内容修改</el-button
                        >
                    </div>
                    <!-- </el-col> -->
                    <!-- </el-row> -->
                    <el-row :gutter="20" class="el-row">
                        <el-col
                            :span="20"
                            v-for="(
                                item, index
                            ) in PayCostDetail.interestsDetailList"
                            :key="item.index"
                        >
                            <span class="exam_span_class">{{
                                index +
                                1 +
                                "、" +
                                item.titleStr +
                                "-----------" +
                                "￥" +
                                item.price
                            }}</span>
                        </el-col>
                    </el-row>
                    <div class="bottom-paycost-info">
                        <!-- <span class="paycost-info-title"
                          >特训营教育基金：-￥{{
                              PayCostDetail.discountPrice ? PayCostDetail.discountPrice : 0
                          }}</span
                      > -->
                        <span class="paycost-info-title redcolor"
                            >费用合计：￥{{
                                PayCostDetail.payAmount
                                    ? PayCostDetail.payAmount
                                    : "0.00"
                            }}</span
                        >
                        <!-- <div class="paycost-info-img">
            <span class="paycostinfo-lefttitle">缴费凭证：</span>
            <el-image
              style="width: 120px; height: 150px; margin-top: 10px"
              :src="PayCostDetail.paymentReceiptImgUrl"
              fit="contain"
              :preview-src-list="[PayCostDetail.paymentReceiptImgUrl]"
            ></el-image>
          </div> -->
                    </div>
                </el-card>
                <el-card style="margin-top: 15px" v-else>
                    <span class="paycost-info-title">暂无缴费信息</span>
                </el-card>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="paymentInformationDialogVisible = false"
                    >取 消</el-button
                >
                <el-button
                    v-if="this.PayCostDetail.status === 2"
                    type="primary"
                    @click="auditPayment"
                    >审核</el-button
                >
                <el-button
                    v-if="this.PayCostDetail.status === 3"
                    type="primary"
                    @click="ConfirmPayment"
                    >确认缴费</el-button
                >
                <el-button
                    v-if="this.PayCostDetail.status === 4"
                    type="primary"
                    @click="refundClicked"
                    >退费</el-button
                >
            </span>
        </el-dialog>
        <!-- 修改缴费信息的dialog -->
        <el-dialog
            title="修改缴费信息"
            :visible.sync="editPaycostDialogVisible"
            width="45%"
        >
            <div label-width="80px">
                <!-- 基本信息 -->
                <el-card style="margin-top: 15px">
                    <el-row :gutter="20" class="title_row">
                        <el-col :span="10">
                            <span class="exam_span_class Title">基本信息</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24" class="el-row">
                        <el-col :span="6">
                            <span class="exam_span_class"
                                >姓名：{{
                                    PayCostDetail.userInfo.userName
                                }}</span
                            >
                        </el-col>
                        <el-col :span="6">
                            <span class="exam_span_class"
                                >性别：{{
                                    PayCostDetail.userInfo.sex === "1"
                                        ? "男"
                                        : "女"
                                }}</span
                            >
                        </el-col>
                        <el-col :span="6">
                            <span class="exam_span_class"
                                >国籍：{{
                                    PayCostDetail.userInfo.nationality
                                }}</span
                            >
                        </el-col>
                        <el-col :span="6">
                            <span class="exam_span_class"
                                >城市：{{
                                    PayCostDetail.userInfo.province +
                                    PayCostDetail.userInfo.city
                                }}</span
                            >
                        </el-col>
                    </el-row>
                    <el-row :gutter="24" class="el-row">
                        <el-col :span="6">
                            <span
                                class="exam_span_class"
                                v-if="
                                    this.PayCostDetail.userInfo.grade === 1 ||
                                    this.PayCostDetail.userInfo.grade === 2
                                "
                                >组别：小初组</span
                            >
                            <span
                                class="exam_span_class"
                                v-else-if="
                                    this.PayCostDetail.userInfo.grade === 4 ||
                                    this.PayCostDetail.userInfo.grade === 3
                                "
                                >组别：小中组</span
                            >
                            <span
                                class="exam_span_class"
                                v-else-if="
                                    this.PayCostDetail.userInfo.grade === 5 ||
                                    this.PayCostDetail.userInfo.grade === 6
                                "
                                >组别：小高组</span
                            >
                            <span
                                class="exam_span_class"
                                v-else-if="
                                    this.PayCostDetail.userInfo.grade === 7 ||
                                    this.PayCostDetail.userInfo.grade === 8 ||
                                    this.PayCostDetail.userInfo.grade === 9
                                "
                                >组别：初中组</span
                            >
                            <span class="exam_span_class" v-else>未知</span>
                        </el-col>
                        <el-col :span="10">
                            <span class="exam_span_class"
                                >学校：{{
                                    PayCostDetail.userInfo.schoolName
                                }}</span
                            >
                        </el-col>
                        <el-col :span="5" v-if="PayCostDetail.userInfo.rank">
                            <span class="exam_span_class"
                                >城市总决赛名次：{{
                                    PayCostDetail.userInfo.rank
                                }}</span
                            >
                        </el-col>
                    </el-row>
                </el-card>
                <!-- 缴费项目 -->
                <el-card style="margin-top: 15px">
                    <el-row :gutter="20" class="title_row">
                        <el-col :span="10">
                            <span class="exam_span_class Title">缴费信息</span>
                        </el-col>
                    </el-row>
                    <div
                        class="paycost-parents"
                        v-for="(item, index) in Parents"
                        :key="item.index"
                    >
                        <div class="userlist-back" v-if="index === 0">
                            <img
                                :src="
                                    item.isSelect == '1'
                                        ? 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-select_new.png'
                                        : 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-unselect_new.png'
                                "
                                alt=""
                                class="userSelectimg"
                                @click="userInfoChange(index)"
                            />
                            <div class="userSelectStr">{{ item.titleStr }}</div>
                            <div class="SelectPriceStr">￥{{ item.price }}</div>
                        </div>
                        <div v-else-if="index === 1" class="userlist-back">
                            <img
                                :src="
                                    item.isSelect == '1'
                                        ? 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-select_new.png'
                                        : 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-unselect_new.png'
                                "
                                alt=""
                                class="userSelectimg"
                                @click="userInfoChange(index)"
                            />
                            <div class="userSelectStr">{{ item.titleStr }}</div>
                            <div class="SelectPriceStr">￥{{ item.price }}</div>
                        </div>
                        <div
                            v-else-if="index === 2 && isShowFellowTwo === true"
                            class="userlist-back"
                        >
                            <img
                                :src="
                                    item.isSelect == '1'
                                        ? 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-select_new.png'
                                        : 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-unselect_new.png'
                                "
                                alt=""
                                class="userSelectimg"
                                @click="userInfoChange(index)"
                            />
                            <div class="userSelectStr">{{ item.titleStr }}</div>
                            <div class="SelectPriceStr">￥{{ item.price }}</div>
                        </div>
                    </div>
                    <!-- <div class="userlist-back" v-if="paycodeData.itso">
                      <img
                          :src="
                              joinItso == 1
                                  ? 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-select_new.png'
                                  : 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-unselect_new.png'
                          "
                          alt=""
                          class="userSelectimg"
                          @click="dualIdentityChange()"
                      />
                      <div class="userSelectStr">
                          {{ paycodeData.itso.titleStr }}
                      </div>
                      <div class="SelectPriceStr">
                          ￥{{ paycodeData.itso.price }}
                      </div>
                  </div> -->
                    <el-row :gutter="20" class="title_row marageTop">
                        <el-col :span="10">
                            <span class="exam_span_class Title">随行亲属</span>
                        </el-col>
                    </el-row>
                    <div
                        class="paycost-parents"
                        v-for="(item, index) in FellowFamily"
                        :key="item.index"
                    >
                        <div class="userlist-backview" v-if="index === 0">
                            <img
                                :src="
                                    item.isSelect == '1'
                                        ? 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-select_new.png'
                                        : 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-unselect_new.png'
                                "
                                alt=""
                                class="userSelectimg"
                                @click="fellowChange(index)"
                            />
                            <div class="userSelectStrchildView">
                                <div class="userSelectStrchild">
                                    {{ item.titleStr }}
                                </div>
                                <div class="userSelectStrchild childStr-class">
                                    {{ item.childStr }}
                                </div>
                            </div>
                            <div class="numberInputclasschild">
                                <img
                                    src="https://cdn.spbcn.org/spbcnappneed/2022guosai_icon_jian.png"
                                    class="upOrDownClass"
                                    v-if="isSelectFellowChild === true"
                                    @click="reduceTap('child')"
                                />
                                <div
                                    class="fellowNumberClass"
                                    v-if="isSelectFellowChild === true"
                                >
                                    {{ paycodeData.fellowFamilyNumChild }}
                                </div>
                                <img
                                    src="https://cdn.spbcn.org/spbcnappneed/2022guosai_icon_jia.png"
                                    class="upOrDownClass"
                                    v-if="isSelectFellowChild === true"
                                    @click="increaseTap('child')"
                                />
                            </div>
                        </div>
                        <div v-else>
                            <div
                                class="userlist-backview"
                                v-if="Parents[2].isSelect === '1'"
                            >
                                <img
                                    :src="
                                        item.isSelect == '1'
                                            ? 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-select_new.png'
                                            : 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-unselect_new.png'
                                    "
                                    alt=""
                                    class="userSelectimg"
                                    @click="fellowChange(index)"
                                />
                                <div class="userSelectStrchildView">
                                    <div class="userSelectStrchild">
                                        {{ item.titleStr }}
                                    </div>
                                </div>
                                <div class="numberInputclasschild">
                                    <img
                                        src="https://cdn.spbcn.org/spbcnappneed/2022guosai_icon_jian.png"
                                        class="upOrDownClass"
                                        v-if="isSelectFellow === true"
                                        @click="reduceTap('adult')"
                                    />
                                    <div
                                        class="fellowNumberClass"
                                        v-if="isSelectFellow === true"
                                    >
                                        {{ paycodeData.fellowFamilyNum }}
                                    </div>
                                    <img
                                        src="https://cdn.spbcn.org/spbcnappneed/2022guosai_icon_jia.png"
                                        class="upOrDownClass"
                                        v-if="isSelectFellow === true"
                                        @click="increaseTap('adult')"
                                    />
                                </div>
                                <div class="SelectPriceStr">
                                    ￥{{ item.price }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="10">
                            <div class="userInfoStr marageTop">
                                04.04-04.05 共2晚
                            </div>
                        </el-col>
                        <el-col :span="13">
                            <div class="roomNumberStr marageTop">
                                房间数：<span class="redcolor"
                                    >{{ paycodeData.roomNum }} </span
                                >间
                            </div>
                        </el-col>
                    </el-row>
                    <div class="lineView"></div>

                    <!-- <div
                      class="userlist-backview marginBottom"
                      @click="trainingCampChange()"
                  >
                      <img
                          class="userSelectimg"
                          :src="
                              paycodeData.trainingCamp === '1'
                                  ? 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-select_new.png'
                                  : 'https://cdn.spbcn.org/spbcnappneed/radio-buttons-unselect_new.png'
                          "
                      />
                      <div class="userSelectStr">国赛集训营</div>
                      <div class="SelectPriceStr">
                          {{ paycodeData.trainingCampPrice }}
                      </div>
                  </div> -->
                    <!-- <el-row
                      :gutter="24"
                      v-if="this.paycodeData.trainingCamp === '1'"
                  >
                      <el-col :span="12">
                          <div class="couponsTitleStr">特训营教育基金</div>
                      </el-col>
                      <el-col :span="11">
                          <div class="couponsPriceStr">
                              -￥{{ paycodeData.codePrice }}
                          </div>
                      </el-col>
                  </el-row> -->
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <div class="couponsTitleStr marageTop">
                                缴费总金额
                            </div>
                        </el-col>
                        <el-col :span="11">
                            <div class="couponsPriceStr marageTop">
                                ￥{{
                                    this.$xcUtils.toFixedPrice(
                                        paycodeData.totalPrice
                                    )
                                }}
                                <span
                                    class="changePriceStr marageTop"
                                    @click="changeTotalPrice"
                                >
                                    修改金额</span
                                >
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" class="title_row marageTop">
                        <el-col :span="10">
                            <span class="exam_span_class Title">修改记录</span>
                        </el-col>
                    </el-row>
                    <div
                        v-for="item in paycodeData.updateRecord"
                        :key="item.index"
                    >
                        <span>{{ item }}</span>
                    </div>
                    <el-input
                        v-model="paycodeData.remark"
                        placeholder="请输入修改备注信息"
                        class="marageTop"
                    ></el-input>
                    <div class="footer-view">
                        <el-button
                            type="primary"
                            @click="changePaycodeClicked()"
                            style="width: 200px"
                            >确 定</el-button
                        >
                    </div>
                </el-card>
            </div>
        </el-dialog>
        <!-- 修改国赛总价格 -->
        <el-dialog
            title="修改总金额"
            :visible.sync="changeTotalPriceDialogVisible"
            width="30%"
        >
            <el-input
                v-model="changeTotalPriceNumber"
                type="number"
                placeholder="请输入要修改的价格"
                oninput="value=value.replace(/[^0-9.]/g,''"
                clearable
                class="input"
                key="priceInput"
            ></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="changeTotalClicked"
                    >修 改</el-button
                >
            </span>
        </el-dialog>
        <!-- 批量转移资格 -->
        <el-dialog
            title="批量转移资格"
            :visible.sync="batchTransferDialogVisible"
            width="30%"
            @close="batchTransferDialogClose"
        >
            <el-form
                :model="batchTransferForm"
                label-width="80px"
                ref="batchTransferFormRef"
                :rules="batchTransferFormRules"
            >
                <el-form-item label="赛程" prop="raceSchedule">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="queryForm.raceSchedule"
                        disabled
                    >
                        <el-option
                            v-for="item in raceScheduleList"
                            :key="item.id"
                            :label="item.scheduleName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属赛区" prop="districtId">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="batchTransferForm.districtId"
                        @change="districtIdChange"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_district"
                            :key="item.id"
                            :label="item.districtName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学校名称" prop="schoolId">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="batchTransferForm.schoolId"
                        clearable
                        filterable
                    >
                        <el-option
                            v-for="item in provincesSchoolList"
                            :key="item.id"
                            :label="item.schoolName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="batchTransferDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="batchTransferClicked"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 缴费审核 -->
        <el-dialog title="审核" :visible.sync="auditDialogVisible" width="30%">
            <el-form
                :model="auditForm"
                label-width="80px"
                ref="auditFormRef"
                :rules="auditFormRules"
            >
                <el-form-item label="审核结果" prop="status">
                    <el-select
                        v-model="auditForm.status"
                        placeholder="请选择"
                        style="width: 100%"
                        @change="handleAuditStatusChange"
                    >
                        <el-option
                            v-for="item in auditStatusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item
                  label="驳回原因"
                  prop="rejectReason"
                  v-if="auditForm.status === 2"
              >
                  <el-input
                      type="textarea"
                      v-model="auditForm.rejectReason"
                      placeholder="请输入驳回原因"
                      :rows="3"
                  ></el-input>
              </el-form-item> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="auditDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitAudit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Vue from 'vue'
import {
  getAllAreaList, finallyqueryTbEnrollmentList, queryTbEnrollmentList, oneGenerateScoreCode, queryDistrictPage, addInitialReplyScore, createInitialReplyScore,
  queryAnswerResultsPage, queryModelPaperResultScore, queryModelInitialReplyScore, queryModelExpectInitialReplyScore, getSchoolListWithCity,
  setTbEnrollmentPromotion, updateInitialReplyScore, querySeasonRaceScheduleFlag, queryPaperResultScore, queryModelPaperExpectResultScore,
  oneKeyGenerateScoreCode, ableApplyNationmatch, getNationmatchPayedInfo, getNationmatchPayedDetails, updateNationmatchPayedDetails,
  updateNationmatchPayed, deletedNationmatchPayedDetails, getNationalItsoOrder, transferBatch, getAllSchoolList, auditPayment
} from '@/http/api'
export default {
  data () {
    return {
      isShowFellowTwo: false, // 是否显示随行家长第二位
      isShowFellowRelatives: false, // 是否显示随行亲属
      isSelectFellow: false, // 随行亲属是否选中
      isSelectFellowChild: false, // 随行儿童是否选中
      paycodeData: {
        remark: ''
      }, // 整体缴费数据
      fellowUnitprice: 0, // 随行人员总价（成人）
      fellowUserSelectId: [], // 随行亲属选择id
      fellowParentsSelectId: [], // 随行家长选择id
      FellowFamily: [], // 随行亲属数组
      changeTotalPriceNumber: 0, // 改变后的总价格
      Parents: [],
      nationmatchIsPayedList: [
        {
          dictCode: 0,
          dictLabel: '未提交',
          dictValue: 0
        },
        {
          dictCode: 1,
          dictLabel: '待审核',
          dictValue: 1
        },
        {
          dictCode: 3,
          dictLabel: '已审核',
          dictValue: 3
        },
        {
          dictCode: 2,
          dictLabel: '已缴费',
          dictValue: 2
        }
      ],
      PayCostDetail: {
        userInfo: {},
        scoreId: ''
      },
      changeTotalPriceDialogVisible: false,
      batchTransferDialogVisible: false,
      editPaycostDialogVisible: false,
      paymentInformationDialogVisible: false,
      importOffScoreDialogVisible: false,
      isUploadFinish: false,
      isCoverUploadFinish: false,
      scheduleFlag: this.$route.query.scheduleFlag,
      addScoreForm: {
        activityType: this.$chnEngStatusCode.activityType,
        scorePhoneOne: '',
        scoreName: '',
        schoolName: '',
        schoolArea: [],
        schoolId: '',
        scoreSex: '',
        scoreGrade: '',
        scoreClass: '',
        scores: '',
        scorePromotion: 0
      },
      addScoreDialogVisible: false,
      importOnlineScoreDialogVisible: false,
      scoreQueryCodeDialogVisible: false,
      singleScoreQueryCodeDialogVisible: false,
      batchScoreQueryCodeDialogVisible: false,
      setPassDialogVisible: false,
      scoreQueryForm: {
        raceSchedule: this.$route.query.raceSchedule,
        schoolArea: [],
        schoolId: '',
        season: this.$chnEngStatusCode.defaultSeason
      },
      dict_countryReserve: [
        {
          dictCode: 1,
          dictLabel: '是',
          dictValue: true
        },
        {
          dictCode: 2,
          dictLabel: '否',
          dictValue: false
        }
      ],
      batchTransferForm: {
        activityType: this.$chnEngStatusCode.activityType,
        districtId: null,
        raceSchedule: this.$route.query.raceSchedule,
        schoolId: null,
        schoolArea: []
      },
      importQueryForm: {
        schoolId: '',
        schoolArea: []
      },
      setPassForm: {
        raceScheduleId: this.$route.query.raceSchedule,
        season: this.$chnEngStatusCode.defaultSeason,
        setRange: null,
        value: '',
        wayOfPromotion: null,
        schoolId: '',
        zoneId: '',
        expectLine: '',
        promotionRatio: '',
        expectNumber: '',
        numberCompetition: ''
      },
      dict_scoreType: [
        {
          dictCode: 2,
          dictLabel: '个人赛-线下初赛',
          dictValue: 1
        }
      ],
      dict_setPassRange: [
        {
          dictCode: 0,
          dictLabel: '赛区',
          dictValue: 0
        },
        {
          dictCode: 1,
          dictLabel: '学校',
          dictValue: 1
        }
      ],
      dict_setPassWay: [
        {
          dictCode: 0,
          dictLabel: '人数',
          dictValue: 0
        },
        {
          dictCode: 2,
          dictLabel: '分数线',
          dictValue: 1
        }
      ],
      batchTransferFormRules: {
        raceSchedule: [{ required: true, message: '请选择', trigger: 'change' }],
        districtId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      setPassFormRules: {
        wayOfPromotion: [{ required: true, message: '请选择', trigger: 'change' }],
        setRange: [{ required: true, message: '请选择', trigger: 'change' }],
        value: [{ required: true, message: '请输入', trigger: 'blur' }],
        zoneId: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      scoreQueryFormRules: {
        season: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      importScoreInfoRules: {
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      editofflineScoreDialogVisible: false,
      isUploadFile: false, // 是否已选择上传文件
      importScoreDialogVisible: false,
      scoreImportInfo: {
        uploadUrl: this.$env.baseIP + 'Initial/Reply/importPreliminaryCompetitionExcel',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      scoreTypeActiveName: 'on-line',
      dataList: [],
      provincesSchoolList: [],
      p_open_type: this.$userInfo.getDataList(this, 'p_open_type').then(value => { this.p_open_type = value }),
      dict_district: [],
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_Group: this.$userInfo.dict_group(),
      dict_qualification: this.$userInfo.dict_qualification(),
      dict_isOnline: this.$userInfo.dict_isOnline(),
      dict_forgoStatus: this.$userInfo.dict_forgoStatus(),
      dict_nationalThrough: this.$userInfo.dict_nationalThrough(),
      areasData: [],
      participation_way: this.$userInfo.participation_way(),
      isPromotion: this.$userInfo.isPromotion(),
      isMatch: this.$userInfo.isMatch(),
      dict_grade: this.$userInfo.getGradeData(),
      total: 0,
      editScoreDialogVisible: false,
      editScoreFormRules: {
        ableApplyNationmatch: [{ required: true, message: '请选择', trigger: 'change' }],
        forgoStatus: [{ required: true, message: '请选择', trigger: 'change' }],
        nationalThrough: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      editScoreForm: {
        forgoStatus: null,
        nationalThrough: null,
        ableApplyNationmatch: null,
        activityType: this.$chnEngStatusCode.activityType,
        raceSchedule: this.scheduleFlag,
        season: this.$chnEngStatusCode.defaultSeason,
        userId: '',
        promotion: null
      },
      editOffScoreForm: {
      },
      dict_seeSignUp: [
        {
          dictCode: '1',
          dictLabel: '已查询',
          dictValue: true
        },
        {
          dictCode: '2',
          dictLabel: '未查询',
          dictValue: false
        }
      ],
      dict_state: [
        {
          dictCode: '1',
          dictLabel: '已签到',
          dictValue: true
        },
        {
          dictCode: '2',
          dictLabel: '未签到',
          dictValue: false
        }
      ],
      scoreFormRules: {
        scorePhoneOne: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        scoreName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolId: [{ required: true, message: '请选择学校', trigger: 'change' }],
        scoreSex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        scoreGrade: [{ required: true, message: '请选择年级', trigger: 'change' }],
        scoretype: [{ required: true, message: '请选择成绩类型', trigger: 'change' }],
        scorePromotion: [{ required: true, message: '请设置晋级状态', trigger: 'change' }],
        scores: [{ required: true, message: '请输入成绩', trigger: 'blur' }]
      },
      queryForm: {},
      dict_sex: this.$userInfo.dict_sex(),
      raceScheduleList: this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.raceScheduleList = value }),
      // 新增的数据
      auditDialogVisible: false,
      auditForm: {
        status: null,
        rejectReason: ''
      },
      auditFormRules: {
        status: [
          { required: true, message: '请选择审核结果', trigger: 'change' }
        ],
        rejectReason: [
          { required: true, message: '请输入驳回原因', trigger: 'blur' }
        ]
      },
      auditStatusOptions: [
        {
          value: 3,
          label: '同意'
        },
        {
          value: -1,
          label: '驳回'
        }
      ],
      refreshTimer: null // 添加定时器变量
    }
  },
  watch: {
    // 监听赛程变化
    scheduleFlag: {
      handler (newVal) {
        // 清除已存在的定时器
        if (this.refreshTimer) {
          clearInterval(this.refreshTimer)
          this.refreshTimer = null
        }
        // // 如果是城市总决选，启动定时器
        // if (newVal === 'CITY_FINALS') {
        //   this.refreshTimer = setInterval(() => {
        //     this.getDataList(this.scoreTypeActiveName, '')
        //   }, 3000) // 每2秒执行一次
        // }
      },
      immediate: true
    }
  },
  beforeDestroy () {
    // 组件销毁前清除定时器
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
    }
  },
  created () {
    // if (!this.$store.state.indexForm) {
    this.queryForm = {
      activityType: this.$chnEngStatusCode.activityType,
      raceSchedule: this.$route.query.raceSchedule,
      promotion: '',
      phone: '',
      userName: '',
      season: this.$chnEngStatusCode.defaultSeason,
      districtId: '',
      schoolId: '',
      isMatch: '',
      scoreCode: null,
      pageNum: 1,
      pageSize: 10,
      forgoStatus: false,
      nationalThrough: false,
      isOnline: null,
      ableApplyNationalmatch: null
    }
    // } else {
    //   this.queryForm = this.$store.state.indexForm
    // }
    this.getAllSchoolList()
    this.seasonHandleChange()
    this.getDistrictList()
    this.getAllAreaList()
  },
  // to：从哪个路由出去的
  // from：从哪个路由进来的
  // next：路由的控制参数，常用的有next(true)和next(false)
  beforeRouteLeave (to, from, next) {
    // 路由离开时清除定时器
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
    }

    if (to.name === 'individualAnswerResult') {
      this.$store.commit('SET_INDEXFORM', this.queryForm)
    } else {
      this.queryForm = {
        activityType: this.$chnEngStatusCode.activityType,
        raceSchedule: this.$route.query.raceSchedule,
        promotion: '',
        phone: '',
        userName: '',
        season: this.$chnEngStatusCode.defaultSeason,
        districtId: '',
        schoolId: '',
        isMatch: '',
        scoreCode: null,
        pageNum: 1,
        pageSize: 10,
        isOnline: null
      }
      this.$store.commit('SET_INDEXFORM', this.queryForm)
    }
    next()
  },
  methods: {
    getAllAreaList () {
      getAllAreaList().then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // 重置搜索条件
    reset () {
      this.queryForm.pageNum = 1
      this.$refs.queryFormRef.resetFields()
      this.queryForm.scoreCode = null
      this.provincesSchoolList = JSON.parse(JSON.stringify(this.allSchoolList))
      this.getDataList(this.scoreTypeActiveName, 'search')
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 赛季选择事件回调
    seasonHandleChange (value) {
      var seasonStr = ''
      if (!value) {
        seasonStr = this.queryForm.season
      } else {
        seasonStr = value
      }
      var formData = {
        activityType: this.$chnEngStatusCode.activityType,
        season: seasonStr,
        scheduleFlag: this.scheduleFlag
      }
      this.searchScheduleWithFlag(formData)
    },
    // 根据赛季标识符查询赛程相关
    searchScheduleWithFlag (formData) {
      querySeasonRaceScheduleFlag(formData).then((res) => {
        this.queryForm.raceSchedule = res.data.id
        this.getDataList(this.scoreTypeActiveName, '')
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 赛程选择
    changeRaceScheduleValue (state) {
      console.log(state)
      if (state === true && this.queryForm.season === '') {
        this.$message.error('请先选择赛季')
      }
    },
    changeRaceScheduleData (value) {
      // 清除已存在的定时器
      if (this.refreshTimer) {
        clearInterval(this.refreshTimer)
        this.refreshTimer = null
      }

      for (let index = 0; index < this.raceScheduleList.length; index++) {
        const element = this.raceScheduleList[index]
        if (element.id === value) {
          this.scheduleFlag = element.scheduleFlag

          //   如果是城市总决选，启动定时器
          if (element.scheduleFlag === 'CITY_FINALS') {
            this.refreshTimer = setInterval(() => {
              console.log('定时器')
              this.getDataList(this.scoreTypeActiveName, '')
            }, 2000) // 每2秒执行一次
          }
        }
      }
      this.scoreTypeActiveName = 'on-line'
      console.log('111111111111')
      this.getDataList(this.scoreTypeActiveName, 'search')
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      if (!value) return
      this.getSchoolListData(value)
    },
    // 获取全部学校list
    getAllSchoolList () {
      getAllSchoolList({}).then((res) => {
        this.allSchoolList = res.data
        this.provincesSchoolList = JSON.parse(JSON.stringify(this.allSchoolList))
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList(this.scoreTypeActiveName, '')
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList(this.scoreTypeActiveName, '')
    },
    // 线上、线下成绩切换
    scoreTypeChengeClick (tab, event) {
      console.log('scoreTypeActiveName', this.scoreTypeActiveName)
      this.getDataList(this.scoreTypeActiveName, 'search')
    },
    // 拉取线下成绩
    getOffLineScore () {
      createInitialReplyScore({ id: this.setPassForm.raceScheduleId }).then((res) => {
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取列表信息
    getDataList (scoreType, getType) {
      console.log('scoreType', scoreType)
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      if (this.scheduleFlag !== 'PRIMARY_SELECTION' && this.scheduleFlag !== 'INDIVIDUAL_FINAL') { // 城市半城市总
        delete this.queryForm.isOnline
        console.log('this.queryForm', this.queryForm)
        if (this.queryForm.raceSchedule === 5 || this.queryForm.raceSchedule === 6) {
          finallyqueryTbEnrollmentList(this.queryForm).then((res) => {
            this.dataList = res.data.list
            this.total = res.data.total
          }).catch((err) => {
            console.log('err', err)
          })
        } else {
          queryTbEnrollmentList(this.queryForm).then((res) => {
            this.dataList = res.data.list
            this.total = res.data.total
          }).catch((err) => {
            console.log('err', err)
          })
        }
      } else {
        if (this.scheduleFlag === 'PRIMARY_SELECTION' && scoreType === 'off-line') { // 初赛线下成绩
          this.queryForm.scoreName = this.queryForm.userName
          queryPaperResultScore(this.queryForm).then((res) => {
            console.log('queryPaperResultScore', res)
            this.dataList = res.data.list
            this.total = res.data.total
          }).catch((err) => {
            console.log('err', err)
          })
        } else { // 初赛、复赛线上成绩，复赛线下资格
          if (this.scheduleFlag === 'INDIVIDUAL_FINAL') {
            // 复赛线下资格
            this.queryForm.isOnline = this.scoreTypeActiveName === 'on-line' ? 1 : 0
          }
          queryAnswerResultsPage(this.queryForm).then((res) => {
            console.log('queryAnswerResultsPage', res)
            this.dataList = res.data.list
            this.total = res.data.total
          }).catch((err) => {
            console.log('err', err)
          })
        }
      }
    },
    // 显示修改晋级弹窗
    showEditDialog (row) {
      console.log('row', row)
      var selectData = { ...row }
      this.editScoreForm.userName = selectData.userName ? selectData.userName : selectData.scoreName
      this.editScoreForm.raceSchedule = selectData.raceSchedule
      this.editScoreForm.ableApplyNationmatch = selectData.promotion
      this.editScoreForm.editType = 'editScore'
      this.editScoreForm.promotion = selectData.promotion
      this.editScoreForm.userId = selectData.userId ? selectData.userId : selectData.id
      console.log('this.editScoreForm', this.editScoreForm)
      this.editScoreDialogVisible = true
    },
    // 显示修改资格弹窗
    showQualificationEditDialog (row) {
      var selectData = { ...row }
      this.editScoreForm.id = selectData.id
      this.editScoreForm.editType = 'qualification'
      this.editScoreForm.forgoStatus = selectData.forgoStatus
      this.editScoreForm.nationalThrough = selectData.nationalThrough
      this.editScoreForm.userName = selectData.userName ? selectData.userName : selectData.scoreName
      this.editScoreForm.ableApplyNationmatch = !!selectData.ableApplyNationmatch
      console.log('this.editScoreForm', this.editScoreForm)
      this.editScoreDialogVisible = true
    },
    // 单个修改线上成绩或资格
    editScore () {
      this.savePromotionInfoData(this.editScoreForm)
    },
    // 修改成绩弹窗关闭
    editScoreDialogClose () {
      this.$refs.editScoreFormRef.resetFields()
    },
    batchTransferDialogClose () {
      this.$refs.batchTransferFormRef.resetFields()
    },
    // 显示修改线上成绩弹窗
    showEditOffLineDialog (data) {
      this.editOffScoreForm = JSON.parse(JSON.stringify(data))
      this.editofflineScoreDialogVisible = true
    },
    // 提交修改线下成绩
    editOffScore () {
      this.saveOfflinePromotionInfoData(this.editOffScoreForm)
    },
    // 修改线下成绩弹窗关闭
    editoffLineScoreDialogClose () {
      this.$refs.editOffScoreFormRef.resetFields()
    },
    // 线下导入成绩学校选择
    selectSchoolChange (e) {
      this.$nextTick(function () {
        this.importQueryForm.schoolName = this.$refs.schoolSelectRef.selectedLabel
      })
    },
    addSelectSchoolChange (e) {
      this.$nextTick(function () {
        this.addScoreForm.schoolName = this.$refs.addScoreSchoolSelectRef.selectedLabel
      })
    },
    // 提交线下成绩
    saveOfflinePromotionInfoData (formData) {
      updateInitialReplyScore(formData).then((res) => {
        this.editofflineScoreDialogVisible = false
        this.$message.success('修改成功')
        this.getDataList(this.scoreTypeActiveName, '')
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 提交线上成绩/国赛资格
    savePromotionInfoData (formData) {
      this.$refs.editScoreFormRef.validate(async valid => {
        if (!valid) return
        if (formData.editType === 'qualification') { // 国赛资格修改
          ableApplyNationmatch(formData).then((res) => {
            this.editScoreDialogVisible = false
            this.$message.success('修改成功')
            this.getDataList(this.scoreTypeActiveName, '')
          }).catch((err) => {
            console.log('返回的err', err)
          })
        } else { // 修改线上成绩
          setTbEnrollmentPromotion(formData).then((res) => {
            this.editScoreDialogVisible = false
            this.$message.success('修改成功')
            this.getDataList(this.scoreTypeActiveName, '')
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }
      })
    },
    // 上传成绩加载
    scoreImportInfoProgress (e) {
      console.log('上传成绩加载_____e', e)
    },
    // 批量上传成功
    scoreImportInfoSuccess (res) {
      Vue.$showLoading.hide()
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.$refs.onLoadScoreImportInfoRef.clearFiles()// 清除文件
      this.isUploadFile = false
      this.isUploadFinish = false
      this.isCoverUploadFinish = false
      if (res.code !== 200) {
        return this.$alert('请修改后再上传，失败原因:' + '<br/>' + res.msg, '上传失败', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      }
      this.getDataList(this.scoreTypeActiveName, '')
      this.importScoreDialogVisible = false
      this.importOnlineScoreDialogVisible = false
      this.$message.success('上传成功')
    },
    scoreImportInfoError (error, file, fileList) {
      console.log('batchImportInfoError', error, file, fileList)
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      Vue.$showLoading.hide()
      this.$message.error(error)
    },
    scoreImportInfoExceed (files, fileList) {
      console.log('fileList', files, fileList)
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.$refs.scoreImportInfoRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    // 查看答题详情
    showViewDetails (rowData) {
      var formData = {
        season: this.queryForm.season,
        activityType: this.$chnEngStatusCode.activityType,
        raceSchedule: this.queryForm.raceSchedule,
        userId: rowData.userId
      }
      this.$router.push(
        {
          path: '/examination_Management/individual_examination_management/schedule_competitions/schedule_Childview/individual_answer_result',
          query: {
            formDataStr: JSON.stringify(formData)
          }
        }
      )
    },
    // 新增成绩
    submitscoreImportInfo () {
      if (!this.queryForm.season || this.queryForm.season === undefined) {
        return this.$message.error('请先选择赛季信息')
      }
      if (this.scheduleFlag === 'PRIMARY_SELECTION') {
        this.scoreImportInfo.uploadUrl = this.$env.baseIP + 'Initial/Reply/importPreliminaryCompetitionExcel'
      } else {
        this.scoreImportInfo.uploadUrl = this.$env.baseIP + 'Initial/Reply/importExcelToUpdate'
      }
      this.$nextTick(function () {
        this.queryForm.schoolName = this.$refs.schoolSelectRef.selectedLabel
        this.$refs.editInfoFormRef.validate(async valid => {
          if (!valid) return
          if (this.isUploadFile === true) {
            this.isUploadFinish = true
            this.$refs.scoreImportInfoRef.submit()
          } else {
            this.$message.error('请先选择要上传的文件')
          }
        })
      })
    },
    // 覆盖成绩
    coverscoreImportInfo () {
      if (!this.queryForm.season || this.queryForm.season === undefined) {
        return this.$message.error('请先选择赛季信息')
      }
      this.scoreImportInfo.uploadUrl = this.$env.baseIP + 'Initial/Reply/updatePreliminaryCompetitionExcel'
      this.queryForm.schoolName = this.$refs.schoolSelectRef.selectedLabel
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        console.log('this.queryForm', this.queryForm)
        if (this.isUploadFile === true) {
          this.$confirm('是否继续覆盖该条件下的所有成绩?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            this.isCoverUploadFinish = true
            this.$refs.scoreImportInfoRef.submit()
          }).catch(() => { })
        } else {
          this.$message.error('请先选择要上传的文件')
        }
      })
    },
    districtIdChange (value) {
      console.log('value', value)
      getSchoolListWithCity({ districtId: value }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
        this.batchTransferForm.schoolId = null
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 批量转移资格
    batchTransferClicked () {
      this.$refs.batchTransferFormRef.validate(async valid => {
        if (!valid) return
        this.$confirm('该操作不可逆,请谨慎操作', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          transferBatch(this.batchTransferForm).then((res) => {
            this.batchTransferDialogVisible = false
            this.$message({
              message: '转移成功',
              type: 'success',
              center: true,
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }).catch(() => { })
      })
    },
    // 监听上传文件弹窗关闭事件
    importScoreDialogClose () {
      //   this.importQueryForm.schoolArea = []
      //   this.importQueryForm.schoolId = ''
      this.$refs.scoreImportInfoRef.clearFiles()// 清除文件
      this.isUploadFile = false
    },
    scoreQueryCodeClicked () {
      if (this.queryForm.schoolArea) {
        this.getSchoolListData(this.queryForm.schoolArea)
        this.scoreQueryForm.schoolArea = this.queryForm.schoolArea
        this.scoreQueryForm.schoolId = this.queryForm.schoolId ? this.queryForm.schoolId : ''
      }
      this.scoreQueryCodeDialogVisible = true
    },
    importOffLineScoreClicked () {
      if (this.queryForm.schoolArea) {
        this.getSchoolListData(this.queryForm.schoolArea)
        this.importQueryForm.schoolArea = this.queryForm.schoolArea
        this.importQueryForm.schoolId = this.queryForm.schoolId
      }
      this.importScoreDialogVisible = true
    },
    // 下载模板
    async downLoadscoreImportInfoStencil () {
      var downLoadUrl = this.$env.baseIP + 'Initial/Reply/importPreliminaryCompetitionTemplate'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithFile(this, downLoadUrl, {})
    },
    //  导出成绩
    downLoadScoreSearchData () {
      var downLoadUrl = this.$env.baseIP + 'Initial/Reply/exportAnswerResultsPage'
      console.log('this.queryForm_____________', this.queryForm)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', this.queryForm)
    },
    // 导入线上成绩
    importScoreClicked () {
      if (this.scoreTypeActiveName === 'on-line' || this.scheduleFlag === 'INDIVIDUAL_FINAL' || this.scheduleFlag === 'WEB_FINALS') {
        // 初赛线上、复赛线上线下
        this.scoreImportInfo.uploadUrl = this.$env.baseIP + 'Initial/Reply/importErollment'
      } else {
        // 初赛线下
        this.scoreImportInfo.uploadUrl = this.$env.baseIP + 'initial/reply/v2/importExcelToUpdate'
      }
      this.importOnlineScoreDialogVisible = true
    },
    beginUpLoadData () {
      this.$refs.onLoadScoreImportInfoRef.submit()
    },
    // 选择文件修改状态
    onChangeLoad (files, fileList) {
      if (fileList.length > 0) {
        this.isUploadFile = true
      }
    },
    // 查看晋级预期
    queryPromotionExpectations () {
      this.$refs.setPassFormRef.validate(async valid => {
        if (!valid) return
        var formData = {
          raceScheduleId: this.$route.query.raceSchedule,
          season: this.setPassForm.season,
          setRange: this.setPassForm.setRange,
          value: this.setPassForm.value,
          wayOfPromotion: this.setPassForm.wayOfPromotion,
          schoolId: this.setPassForm.schoolId,
          zoneId: this.setPassForm.zoneId
        }
        if (this.scoreTypeActiveName === 'on-line' || this.scheduleFlag === 'INDIVIDUAL_FINAL') {
          // 查看初赛线上、复赛线上、线下晋级预期
          queryModelPaperExpectResultScore(formData).then((res) => {
            this.setPassForm.numberCompetition = res.data.numberCompetition
            this.setPassForm.expectNumber = res.data.expectNumber
            this.setPassForm.expectLine = res.data.expectLine
            this.setPassForm.promotionRatio = res.data.promotionRatio
          }).catch((err) => {
            console.log('返回的err', err)
          })
        } else {
          // 查看初赛线下晋级预期
          queryModelExpectInitialReplyScore(formData).then((res) => {
            this.setPassForm.numberCompetition = res.data.numberCompetition
            this.setPassForm.expectNumber = res.data.expectNumber
            this.setPassForm.expectLine = res.data.expectLine
            this.setPassForm.promotionRatio = res.data.promotionRatio
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }
      })
    },
    setPass () {
      this.$confirm('晋级结果将以晋级预期情况来设置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.$refs.setPassFormRef.validate(async valid => {
          if (!valid) return
          if (this.scoreTypeActiveName === 'on-line' || this.scheduleFlag === 'INDIVIDUAL_FINAL') { // 设置线上晋级
            queryModelPaperResultScore(this.setPassForm).then((res) => {
              console.log('queryModelPaperResultScore', res)
              this.setPassDialogVisible = false
              this.$message.success('设置成功')
              this.getDataList(this.scoreTypeActiveName, '')
            }).catch((err) => {
              console.log('err', err)
            })
          } else { // 设置线下晋级
            queryModelInitialReplyScore(this.setPassForm).then((res) => {
              console.log('queryModelPaperResultScore', res)
              this.setPassDialogVisible = false
              this.$message.success('设置成功')
              this.getDataList(this.scoreTypeActiveName, '')
            }).catch((err) => {
              console.log('err', err)
            })
          }
        })
      }).catch(() => { })
    },
    // 批量设置晋级弹窗关闭
    setPassDialogClose () {
      this.setPassSchoolList = []
      this.$refs.setPassFormRef.resetFields()
    },
    // 生成成绩查询码
    generateScoreQueryCode () {
      this.$refs.scoreQueryFormRef.validate(async valid => {
        if (!valid) return
        oneKeyGenerateScoreCode(this.scoreQueryForm).then((res) => {
          this.scoreQueryCodeDialogVisible = false
          this.$message.success('生成成功')
          this.getDataList(this.scoreTypeActiveName, '')
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 成绩查询码弹窗关闭
    scoreQueryCodeDialogClose () {
      this.$refs.scoreQueryFormRef.resetFields()
    },
    // 单个生成成绩查询码
    generateScoreSearchCode (rowData) {
      this.$confirm('是否确认生成该考生成绩查询码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        oneGenerateScoreCode({ id: rowData.id }).then((res) => {
          console.log('oneGenerateScoreCode', res)
          this.$message.success('生成成功')
          this.getDataList(this.scoreTypeActiveName, '')
        }).catch((err) => {
          console.log('err', err)
        })
      }).catch(() => { })
    },
    // 导出成绩查询码
    downLoadScoreSearchCode () {
      var downLoadUrl = this.$env.baseIP + 'Initial/Reply/excelPaperResultScoreCode'
      const queryForms = JSON.parse(JSON.stringify(this.queryForm))
      delete queryForms.schoolArea
      console.log('queryForms', queryForms)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', queryForms)
    },
    checkDetail (rowData) {
      this.$router.push(
        {
          path: '/c_data_Management/c_player_registInfo_details',
          query: {
            userId: rowData.userId
          }
        }
      )
    },
    // 查看缴费状态
    getPaycostDetail (row) {
      this.showPayCostDialog(row.id)
    },
    // 查看缴费信息
    showPayCostDialog (scoreid) {
      var paramsData = {}
      paramsData.activityType = this.$chnEngStatusCode.activityType
      paramsData.enrollmentId = scoreid
      getNationmatchPayedInfo(paramsData).then((res) => {
        this.paymentInformationDialogVisible = true
        this.PayCostDetail = res.data
        this.PayCostDetail.scoreId = scoreid
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 修改点击事件
    changePaycodeClicked () {
      console.log(this.paycodeData)
      if (!this.paycodeData.remark || this.paycodeData.remark === '') {
        return this.$message.error('请输入修改备注信息')
      }
      this.$confirm('请再次确认是否修改?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.changeUoloadData()
      }).catch(() => { })
    },
    // 确认修改
    changeUoloadData () {
      console.log('paycodeData', this.paycodeData)
      this.paycodeData.joinItso = this.joinItso
      updateNationmatchPayedDetails(this.paycodeData).then((res) => {
        this.editPaycostDialogVisible = false
        this.$message.success('修改成功')
        this.showPayCostDialog(this.PayCostDetail.scoreId)
        this.selectdisplayModeChanged()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    auditPayment () {
      this.auditForm.status = null
      this.auditForm.rejectReason = ''
      this.auditDialogVisible = true
    },
    // 新增的方法
    handleAuditStatusChange (value) {
      if (value === 1) {
        this.auditForm.rejectReason = ''
      }
    },

    submitAudit () {
      this.$refs.auditFormRef.validate(valid => {
        if (!valid) return
        // TODO: 调用审核接口
        const params = {
          enrollmentId: this.PayCostDetail.scoreId,
          status: this.auditForm.status
          //   rejectReason: this.auditForm.rejectReason
        }

        console.log('审核参数:', params)
        // 这里添加调用审核接口的代码
        auditPayment(params).then((res) => {
          this.auditDialogVisible = false
          this.paymentInformationDialogVisible = false
          this.$message.success('审核成功')
          this.getDataList(this.scoreTypeActiveName, '')
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 确认缴费点击事件
    ConfirmPayment () {
      this.paymentInformationDialogVisible = false
      this.$confirm('是否确认修改缴费信息状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.modifyingPayment()
      }).catch(() => { })
    },
    // 确认缴费
    modifyingPayment () {
      var paramsData = {}
      paramsData.enrollmentId = this.PayCostDetail.scoreId
      paramsData.activityType = this.$chnEngStatusCode.activityType
      updateNationmatchPayed(paramsData).then((res) => {
        this.$message.success('确认成功')
        this.getDataList(this.scoreTypeActiveName, '')
        // this.getNetworkFinalsMatchInfo()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 修改缴费内容点击事件获取缴费详情
    changePaycostClicked () {
      var paramsData = {}
      paramsData.activityType = this.$chnEngStatusCode.activityType
      paramsData.enrollmentId = this.PayCostDetail.scoreId
      getNationmatchPayedDetails(paramsData).then((res) => {
        for (let index = 0; index < res.data.fellowFamilys.length; index++) {
          var selectIndex = Number(res.data.fellowFamilys[index])
          res.data.fellowFamily[selectIndex].isSelect = '1'
        }
        this.Parents = res.data.parentsList
        for (let j = 0; j < res.data.parents.length; j++) {
          var index = Number(res.data.parents[j])
          this.Parents[index].isSelect = '1'
        }
        this.paycodeData = res.data
        this.joinItso = res.data.joinItso
        this.changeTotalPriceNumber = Number(res.data.totalPrice)
        this.paycodeData.enrollmentId = this.PayCostDetail.scoreId
        this.paycodeData.activityType = this.$chnEngStatusCode.activityType
        this.FellowFamily = res.data.fellowFamily
        this.editPaycostDialogVisible = true
        this.createdUI()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 初始化数据显示
    createdUI () {
      var seletParentsId = []
      var ParentsList = this.Parents
      for (let index = 0; index < ParentsList.length; index++) {
        const element = ParentsList[index]
        if (element.isSelect === '1') {
          seletParentsId.push(element.id)
        }
        if (index === 1) {
          if (element.isSelect === '1') { // 如果随行家长1是选中的 则显示随行家长2 否则不显示随行家长2 并且不显示随行亲属里面的成人亲属
            this.isShowFellowTwo = true
          } else {
            this.isShowFellowTwo = false
            this.isSelectFellow = false
            this.FellowFamily[1].isSelect = '0'
            this.paycodeData.fellowFamilyNum = '0'
          }
        }
        if (index === 2) {
          if (element.isSelect === '1') { // 如果随行家长2是选中的 则显示随行亲属 否则不显示随行亲属
            this.isSelectFellow = true
          } else {
            this.isSelectFellow = false
            this.FellowFamily[1].isSelect = '0'
            this.paycodeData.fellowFamilyNum = '0'
          }
        }
      }
      this.paycodeData.parents = seletParentsId
      var seletId = []
      var userlist = this.FellowFamily
      for (let index = 0; index < userlist.length; index++) {
        const element = userlist[index]
        if (element.isSelect === '1') { // 先统一将选中的类型添加到已选id数组里面
          seletId.push(element.id)
        }
        if (index === 0) { // 判断是否选中了随行儿童
          if (element.isSelect === '1') {
            this.isSelectFellowChild = true
          } else {
            this.isSelectFellowChild = false
          }
        }
        if (index === 1) { // 判断是否选中了随行亲属
          if (element.isSelect === '1') {
            this.isSelectFellow = true
          } else {
            this.isSelectFellow = false
          }
          this.fellowUnitprice = Number(element.price)
        }
      }
      this.paycodeData.fellowFamilys = seletId
    },
    // 显示修改总价弹窗
    changeTotalPrice () {
      this.changeTotalPriceDialogVisible = true
    },
    // 确认修改总价格
    changeTotalClicked () {
      this.paycodeData.totalPrice = String(this.changeTotalPriceNumber)
      this.changeTotalPriceDialogVisible = false
    },
    // 退费信息点击事件
    refundClicked () {
      this.paymentInformationDialogVisible = false
      this.$confirm('是否进行退费操作? 此操作不会自动退费，只会关闭订单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.refundOperation()
      }).catch(() => { })
    },
    // 国赛退费
    refundOperation () {
      var paramsData = {}
      paramsData.activityType = this.$chnEngStatusCode.activityType
      paramsData.enrollmentId = this.PayCostDetail.scoreId
      deletedNationmatchPayedDetails(paramsData).then((res) => {
        this.$message.success('操作成功')
        this.getDataList(this.scoreTypeActiveName, '')
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 双身份点击事件
    dualIdentityChange () {
      var formData = {}
      formData.activityType = this.$chnEngStatusCode.itsoActivityType
      formData.enrollmentId = this.PayCostDetail.scoreId
      getNationalItsoOrder(formData).then((res) => {
        if (res.code === 200) {
          if (res.data === true) {
            this.joinItso = this.joinItso === 1 ? 0 : 1
            this.Parents[0].isSelect = this.joinItso === 1 ? '0' : '1'
            this.createdUI()
            this.calculateTheTotalPrice()
            this.countTheRooms()
          } else {
            this.$message.error('未缴纳团体赛费用,请选择国赛参赛选手！')
          }
        }
      })
    },
    // 随行家长点击事件
    userInfoChange (selectIndex) {
      this.Parents[selectIndex].isSelect = this.Parents[selectIndex].isSelect === '1' ? '0' : '1'
      if (selectIndex === 0) {
        this.joinItso = this.Parents[selectIndex].isSelect === '1' ? 0 : 1
      }
      this.createdUI()
      this.calculateTheTotalPrice()
      this.countTheRooms()
    },
    //  随行亲属选择
    fellowChange (selectIndex) {
      this.FellowFamily[selectIndex].isSelect = this.FellowFamily[selectIndex].isSelect === '1' ? '0' : '1'
      if (selectIndex === 0 && this.FellowFamily[selectIndex].isSelect === '1') {
        this.paycodeData.fellowFamilyNumChild = '1'
      }
      if (selectIndex === 0 && this.FellowFamily[selectIndex].isSelect === '0') {
        this.paycodeData.fellowFamilyNumChild = '0'
      }
      if (selectIndex === 1 && this.FellowFamily[selectIndex].isSelect === '1') {
        this.paycodeData.fellowFamilyNum = '1'
      }
      if (selectIndex === 1 && this.FellowFamily[selectIndex].isSelect === '0') {
        this.paycodeData.fellowFamilyNum = '0'
      }
      this.createdUI()
      this.calculateTheTotalPrice()
      this.countTheRooms()
    },
    // 随行人数增加点击
    increaseTap: function (type) {
      var userlist = this.FellowFamily
      if (type === 'adult') {
        if (userlist[1].isSelect === '1') {
          var fellowfamilynumber = Number(this.paycodeData.fellowFamilyNum)
          this.paycodeData.fellowFamilyNum = String(fellowfamilynumber += 1)
          this.calculateTheTotalPrice()
          this.countTheRooms()
        }
      } else {
        if (userlist[0].isSelect === '1') {
          var fellowfamilychildnumber = Number(this.paycodeData.fellowFamilyNumChild)
          this.paycodeData.fellowFamilyNumChild = String(fellowfamilychildnumber += 1)
        }
      }
    },
    // 随行人数减少点击
    reduceTap (type) {
      var userlist = this.FellowFamily
      if (type === 'adult') {
        if (userlist[1].isSelect === '1') {
          var fellowfamilynumber = Number(this.paycodeData.fellowFamilyNum)
          if (fellowfamilynumber === 1) {
            return
          }
          this.paycodeData.fellowFamilyNum = String(fellowfamilynumber -= 1)
          this.calculateTheTotalPrice()
          this.countTheRooms()
        }
      } else {
        if (userlist[0].isSelect === '1') {
          var fellowfamilychildnumber = Number(this.paycodeData.fellowFamilyNumChild)
          if (fellowfamilychildnumber === 1) {
            return
          }
          this.paycodeData.fellowFamilyNumChild = String(fellowfamilychildnumber -= 1)
        }
      }
    },
    // 根据所选内容计算总价格
    calculateTheTotalPrice () {
      // 参赛选手和家长价格计算
      var parentsTotalPrice = 0
      for (let index = 0; index < this.Parents.length; index++) {
        const element = this.Parents[index]
        if (element.isSelect === '1') {
          parentsTotalPrice += Number(element.price)
        }
      }
      // 随行亲属价格计算
      var relativesTotalfees = 0
      if (this.isSelectFellow === true) {
        relativesTotalfees += (this.fellowUnitprice * Number(this.paycodeData.fellowFamilyNum))
      }
      //   双身份选手计算价格
      var dualIdentity = 0
      console.log('this.joinItso', this.joinItso)
      if (this.joinItso === 1) {
        dualIdentity = Number(this.paycodeData.itso.price)
      }
      console.log('dualIdentity______', dualIdentity)
      this.paycodeData.competitionFees = String(parentsTotalPrice + relativesTotalfees + dualIdentity)
      // 国赛集训营
      var trainingcampfees = 0
      if (this.paycodeData.trainingCamp === '1') {
        trainingcampfees = Number(this.paycodeData.trainingCampPrice)
        trainingcampfees -= Number(this.paycodeData.codePrice)
      }
      this.paycodeData.trainingCampFees = String(trainingcampfees)
      this.paycodeData.totalPrice = String(trainingcampfees + relativesTotalfees + parentsTotalPrice + dualIdentity)
      this.changeTotalPriceNumber = Number(this.paycodeData.totalPrice)
    },
    // 根据随行亲属人数计算房间数
    countTheRooms () {
      var roomnumber = 1
      if (this.FellowFamily[1].isSelect === '1') {
        console.log('999')
        var adultNumber = Number(this.paycodeData.fellowFamilyNum) + ((this.paycodeData.parents.length - 1) < 1 ? 0 : (this.paycodeData.parents.length - 1))
        if (adultNumber === 2) {
          console.log('222')
          roomnumber = 1
        } else {
          roomnumber = Math.floor(adultNumber / 2)
          console.log('1111')
          if (adultNumber % 2 !== 0) {
            roomnumber = roomnumber + 1
          }
        }
      }
      this.paycodeData.roomNum = String(roomnumber)
    },
    // 冠军集训营点击事件
    trainingCampChange () {
      if (this.paycodeData.trainingCamp) {
        if (this.paycodeData.trainingCamp === '1') {
          this.paycodeData.trainingCamp = '0'
        } else {
          this.paycodeData.trainingCamp = '1'
        }
      } else {
        this.paycodeData.trainingCamp = '1'
      }
      this.calculateTheTotalPrice()
    },
    gradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (data.scoreGrade) {
            if (element.dictValue === Number(data.scoreGrade)) {
              temp = element.dictLabel
            }
          }
          if (data.grade) {
            if (element.dictValue === Number(data.grade)) {
              temp = element.dictLabel
            }
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_Group) {
        if (Object.hasOwnProperty.call(this.dict_Group, key)) {
          const element = this.dict_Group[key]
          if (element.dictValue === Number(data.enrollmentLevel)) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    participationFormatter (data) {
      var temp = '-'
      for (const key in this.participation_way) {
        if (Object.hasOwnProperty.call(this.participation_way, key)) {
          const element = this.participation_way[key]
          if (element.dictValue === data.grade + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    openTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.p_open_type) {
        if (Object.hasOwnProperty.call(this.p_open_type, key)) {
          const element = this.p_open_type[key]
          if (element.dictValue === data.openType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    addScoreDialogVisibleClicked () {
      this.addScoreForm.raceSchedule = this.queryForm.raceSchedule
      if (this.queryForm.schoolArea) {
        this.getSchoolListData(this.queryForm.schoolArea)
        this.addScoreForm.schoolArea = this.queryForm.schoolArea
        this.addScoreForm.schoolId = this.queryForm.schoolId
      }
      this.addScoreDialogVisible = true
    },
    addScore () {
      this.$refs.addScoreFormRef.validate(async valid => {
        if (!valid) return
        addInitialReplyScore(this.addScoreForm).then((res) => {
          this.$message.success('新增成绩成功')
          this.addScoreDialogVisible = false
          this.getPreliminaryRoundsMatchInfo()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 单项新增
    addScoreDialogClose () {
      this.provincesSchoolList = []
      this.$refs.addScoreFormRef.resetFields()
      this.addScoreDialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.exam_span_class {
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    font-weight: 500;
    color: #616161;
}
.Title {
    font-size: 16px !important;
    color: black !important;
}
.nationalnum {
    font-size: 18px;
    font-weight: bold;
    color: #ff0000;
    letter-spacing: 1px;
}
.title_class {
    display: -moz-inline-box;
    display: inline-block;
    width: 120px;
}
.score-querytype-class {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.change_paycost_btn {
    width: 140px;
    height: 40px;
    margin-left: 20px;
}
.input {
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
}
.exam_span_class {
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    font-weight: 500;
    color: #616161;
}
.topmenu-backview {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    justify-content: flex-start;
}
.table_span_class {
    font-size: 8px;
    color: #616161;
}
.Title {
    font-size: 16px !important;
    color: black !important;
}
.redcolor {
    color: red;
}
.lightgreen-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.payment-Voucher-class {
    width: 80px;
    height: 80px;
}
.bottom-paycost-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.paycost-info-title {
    width: 100%;
    text-align: right;
    font-size: 16px;
    line-height: 30px;
    font-weight: 900;
}
.paycost-info-img {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.paycostinfo-lefttitle {
    font-size: 16px;
    margin-top: 10px;
    line-height: 30px;
    font-weight: 900;
}
.elformitemclass {
    margin-bottom: 0px !important;
}
.paycost-parents {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.userlist-back {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
}
.userSelectimg {
    width: 20px;
    height: 20px;
}
.userlist-backview {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
}
.userSelectStr {
    width: 350px;
    font-size: 16px;
    color: black;
    text-align: left;
    margin-left: 10px;
}
.userSelectStrchild {
    width: 210px;
    font-size: 16px;
    color: black;
    text-align: left;
}
.userSelectStrchildView {
    width: 240px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.marageTop {
    margin-top: 20px;
}
.childStr-class {
    font-size: 12px;
}
.numberInputclasschild {
    width: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.upOrDownClass {
    width: 20px;
    height: 20px;
}
.fellowNumberClass {
    font-size: 16px;
    color: black;
    text-align: center;
    padding: 0px 20px;
}
.userInfoStr {
    font-size: 16px;
    text-align: left;
    color: black;
    /* height: 60rpx;
  line-height: 60rpx; */
}
.roomNumberStr {
    font-size: 16px;
    text-align: left;
    color: black;
    text-align: right;
    font-weight: 700;
}
.homeNumberView {
    width: 80%;
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 16px;
    color: black;
    background-color: #fec200;
    border-radius: 12.5px;
}
.lineView {
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 20px 0px;
}

.marginBottom {
    margin-bottom: 20px;
}
.couponsTitleStr {
    font-size: 16px;
    color: black;
    text-align: left;
    font-weight: 900;
}

.couponsPriceStr {
    font-size: 16px;
    font-weight: 700;
    color: black;
    text-align: right;
}
.changePriceStr {
    font-size: 12px;
    color: rgb(42, 81, 255);
    text-align: right;
    text-decoration: underline;
}
.footer-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.SelectPriceStr {
    font-size: 16px;
    font-weight: 700;
    color: black;
    text-align: center;
    position: absolute;
    right: 70px;
}
.change_paycost_btn {
    width: 140px;
    height: 40px;
    margin-left: 20px;
}
.redcolor {
    color: red;
    font-size: 20px;
}
// /deep/ input::-webkit-outer-spin-button,
// /deep/ input::-webkit-inner-spin-button {
//   -webkit-appearance: none !important;
// }
// /deep/ input[type="number"] {
//   -moz-appearance: textfield !important;
// }

// // 添加以下样式来设置表格背景色
// /deep/ .el-tabs--border-card {
// .el-tab-pane:nth-child(1) {
//   .el-table {
//     background-color: #eaeaff; // 更深的蓝色背景

//     .el-table__row {
//       background-color: #eaeaff;

//       &:hover {
//         background-color: #e0e0ff !important;
//       }
//     }

//     .el-table__row--striped {
//       background-color: #e0e0ff;
//     }
//   }
// }

// .el-tab-pane:nth-child(2) {
//   .el-table {
//     background-color: #ffeaea; // 更深的红色背景

//     .el-table__row {
//       background-color: #ffeaea;

//       &:hover {
//         background-color: #ffe0e0 !important;
//       }
//     }

//     .el-table__row--striped {
//       background-color: #ffe0e0;
//     }
//   }
// }
// }

// 单个表格的背景色也相应加深
// .el-card {
// .el-table:not(.el-tabs--border-card .el-table) {
//   background-color: #eaeaff;

//   .el-table__row {
//     background-color: #eaeaff;

//     &:hover {
//       background-color: #e0e0ff !important;
//     }
//   }

//   .el-table__row--striped {
//     background-color: #e0e0ff;
//   }
// }
// }

// 优化整体布局和间距
.el-card {
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

    .title_row {
        margin-bottom: 20px;

        .title_class {
            font-size: 16px;
            font-weight: 600;
            color: #303133;
            position: relative;
            padding-left: 12px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 4px;
                height: 16px;
                background: #409eff;
                border-radius: 2px;
            }
        }
    }

    // 优化表单样式
    .filter_Form {
        padding: 20px 0;

        .el-form-item {
            margin-bottom: 18px;

            .el-form-item__label {
                font-weight: 500;
            }

            .el-select,
            .el-cascader,
            .el-input {
                width: 100%;
            }
        }
    }

    // 优化按钮组样式
    .el-row[type="flex"] {
        padding: 10px 0;

        .el-button {
            padding: 10px 20px;
            font-weight: 500;

            &.el-button--primary {
                background: #409eff;
                border-color: #409eff;

                &:hover {
                    background: #66b1ff;
                    border-color: #66b1ff;
                }
            }
        }
    }

    // 优化表格样式
    .el-table {
        margin-top: 15px;
        border-radius: 8px;
        overflow: hidden;

        // 表头样式
        /deep/ .el-table__header-wrapper {
            th {
                background-color: #f0f2f5;
                color: #303133;
                font-weight: 600;
                height: 50px;
                padding: 8px 0;
            }
        }

        // 表格主体样式
        /deep/ .el-table__body-wrapper {
            tr {
                &:nth-child(odd) {
                    background-color: #f5ffe8; // 奇数行背景色
                }

                &:nth-child(even) {
                    background-color: #ffbcbc; // 偶数行背景色
                }

                &:hover > td {
                    background-color: #fff2d3 !important;
                }

                td {
                    padding: 12px 0;
                    border-bottom: 1px solid #d2d2d2;
                }
            }
        }

        // 操作按钮样式
        .el-button--text {
            padding: 0 8px;
            font-weight: normal;
            color: #409eff;

            &:hover {
                color: #66b1ff;
            }

            & + .el-button--text {
                margin-left: 8px;
            }
        }

        // 状态标签样式
        .el-tag {
            border-radius: 4px;
            padding: 0 10px;
            height: 24px;
            line-height: 24px;

            &.el-tag--success {
                background-color: #eaffe0;
                border-color: #9cfe6b;
                color: #67c23a;
            }

            &.el-tag--danger {
                background-color: #ffebeb;
                border-color: #fd5d5d;
                color: #fa6060;
            }

            &.el-tag--warning {
                background-color: #fff4e6;
                border-color: #f9b95e;
                color: #e6a23c;
            }
        }
    }
}

// 优化Tab样式
.el-tabs--border-card {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

    .el-tabs__header {
        background-color: #f5f7fa;
        border-bottom: 1px solid #e4e7ed;

        .el-tabs__item {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #606266;

            &.is-active {
                color: #409eff;
                background-color: #fff;
                border-right-color: #e4e7ed;
                border-left-color: #e4e7ed;
            }
        }
    }

    // 表格背景色优化
    .el-tab-pane:nth-child(1) {
        .el-table {
            background-color: #ffffff;

            .el-table__row {
                background-color: #f5f7fa;

                &:hover {
                    background-color: #e6effd !important;
                }
            }

            .el-table__row--striped {
                background-color: #eef1f6;
            }
        }
    }

    .el-tab-pane:nth-child(2) {
        .el-table {
            background-color: #fdf6ec;

            .el-table__row {
                background-color: #fdf6ec;

                &:hover {
                    background-color: #faecd8 !important;
                }
            }

            .el-table__row--striped {
                background-color: #fdf6ec;
            }
        }
    }
}

// 优化分页样式
.el-pagination {
    margin-top: 20px;
    padding: 20px 0;
    text-align: right;

    .el-pagination__total,
    .el-pagination__sizes {
        margin-right: 16px;
    }

    .el-pager li {
        border-radius: 4px;

        &.active {
            background-color: #409eff;
            color: #fff;
        }
    }
}

// 优化弹窗样式
.el-dialog {
    border-radius: 8px;

    .el-dialog__header {
        padding: 20px;
        border-bottom: 1px solid #e4e7ed;

        .el-dialog__title {
            font-size: 16px;
            font-weight: 600;
            color: #303133;
        }
    }

    .el-dialog__body {
        padding: 30px 20px;
    }

    .el-dialog__footer {
        padding: 20px;
        border-top: 1px solid #e4e7ed;
    }
}

// 删除或注释掉之前的表格背景色样式（第一处定义）
// /deep/ .el-tabs--border-card {
//   .el-tab-pane:nth-child(1) {
//     .el-table {...}
//   }
//   .el-tab-pane:nth-child(2) {
//     .el-table {...}
//   }
// }

// 修改第二处定义，确保样式生效
.el-tabs--border-card {
    // ... 其他tab样式保持不变 ...

    // 修改表格背景色样式
    .el-tab-pane:nth-child(1) {
        /deep/ .el-table {
            .el-table__body-wrapper {
                tr {
                    &:nth-child(odd) {
                        background-color: #ffffff !important; // 线上成绩奇数行
                        td {
                            background-color: #f8faff !important;
                        }
                    }

                    &:nth-child(even) {
                        background-color: #f0feec !important; // 线上成绩偶数行
                        td {
                            background-color: #f0f5ff !important;
                        }
                    }

                    &:hover > td {
                        background-color: #fff2d3 !important;
                    }
                }
            }
        }
    }

    .el-tab-pane:nth-child(2) {
        /deep/ .el-table {
            .el-table__body-wrapper {
                tr {
                    &:nth-child(odd) {
                        background-color: #ffffff !important; // 线下成绩奇数行
                        td {
                            background-color: #fff9f9 !important;
                        }
                    }

                    &:nth-child(even) {
                        background-color: #f0feec !important; // 线下成绩偶数行
                        td {
                            background-color: #f0f5ff !important;
                        }
                    }

                    &:hover > td {
                        background-color: #fff2d3 !important;
                    }
                }
            }
        }
    }
}

// 修改按钮hover效果
.el-button--text {
    &:hover {
        opacity: 0.8;
        text-decoration: underline;
    }
}

// 为不同按钮添加特定hover效果
.el-button--text[style*="color: #409EFF"]:hover {
    color: #66b1ff !important;
}

.el-button--text[style*="color: #67C23A"]:hover {
    color: #85ce61 !important;
}

.el-button--text[style*="color: #E6A23C"]:hover {
    color: #ebb563 !important;
}

// 按钮颜色样式
.blue-text-btn {
    color: #409eff !important;
    &:hover {
        color: #66b1ff !important;
        opacity: 0.8;
        text-decoration: underline;
    }
}

.green-text-btn {
    color: #67c23a !important;
    &:hover {
        color: #85ce61 !important;
        opacity: 0.8;
        text-decoration: underline;
    }
}

.orange-text-btn {
    color: #e63c3c !important;
    &:hover {
        color: #c53e3e !important;
        opacity: 0.8;
        text-decoration: underline;
    }
}

// 修改表格背景色样式，增加固定列的样式
.el-tabs--border-card {
    .el-tab-pane:nth-child(1) {
        /deep/ .el-table {
            .el-table__body-wrapper {
                tr {
                    &:nth-child(odd) {
                        background-color: #ffffff !important;
                        td {
                            background-color: #ffffff !important;
                        }
                        // 固定列样式
                        .el-table__fixed-right-patch {
                            background-color: #ffffff !important;
                        }
                        .el-table__fixed-right {
                            td {
                                background-color: #ffffff !important;
                            }
                        }
                    }

                    &:nth-child(even) {
                        background-color: #f0feec !important;
                        td {
                            background-color: #f0feec !important;
                        }
                        // 固定列样式
                        .el-table__fixed-right-patch {
                            background-color: #f0feec !important;
                        }
                        .el-table__fixed-right {
                            td {
                                background-color: #f0feec !important;
                            }
                        }
                    }

                    &:hover > td {
                        background-color: #fff2d3 !important;
                    }
                    &:hover {
                        .el-table__fixed-right {
                            td {
                                background-color: #fff2d3 !important;
                            }
                        }
                    }
                }
            }

            // 固定列的背景色
            .el-table__fixed-right {
                .el-table__fixed-body-wrapper {
                    tr {
                        &:nth-child(odd) td {
                            background-color: #ffffff !important;
                        }
                        &:nth-child(even) td {
                            background-color: #f0feec !important;
                        }
                        &:hover td {
                            background-color: #fff2d3 !important;
                        }
                    }
                }
            }
        }
    }

    .el-tab-pane:nth-child(2) {
        /deep/ .el-table {
            .el-table__body-wrapper {
                tr {
                    &:nth-child(odd) {
                        background-color: #ffffff !important;
                        td {
                            background-color: #ffffff !important;
                        }
                        // 固定列样式
                        .el-table__fixed-right-patch {
                            background-color: #ffffff !important;
                        }
                        .el-table__fixed-right {
                            td {
                                background-color: #ffffff !important;
                            }
                        }
                    }

                    &:nth-child(even) {
                        background-color: #f0feec !important;
                        td {
                            background-color: #f0feec !important;
                        }
                        // 固定列样式
                        .el-table__fixed-right-patch {
                            background-color: #f0feec !important;
                        }
                        .el-table__fixed-right {
                            td {
                                background-color: #f0feec !important;
                            }
                        }
                    }

                    &:hover > td {
                        background-color: #fff2d3 !important;
                    }
                    &:hover {
                        .el-table__fixed-right {
                            td {
                                background-color: #fff2d3 !important;
                            }
                        }
                    }
                }
            }

            // 固定列的背景色
            .el-table__fixed-right {
                .el-table__fixed-body-wrapper {
                    tr {
                        &:nth-child(odd) td {
                            background-color: #ffffff !important;
                        }
                        &:nth-child(even) td {
                            background-color: #f0feec !important;
                        }
                        &:hover td {
                            background-color: #fff2d3 !important;
                        }
                    }
                }
            }
        }
    }
}

// 专门针对城市总决选表格的样式
.el-card {
    /deep/ .el-table {
        // 主体表格hover效果
        tbody tr {
            &:hover > td {
                background-color: #fff2d3 !important;
            }
        }

        // 确保所有固定列和主体内容同步高亮
        .el-table__body-wrapper,
        .el-table__fixed-right .el-table__fixed-body-wrapper,
        .el-table__fixed .el-table__fixed-body-wrapper {
            tr {
                &:nth-child(odd) {
                    background-color: #ffffff !important;
                    td {
                        background-color: #ffffff !important;
                    }
                }

                &:nth-child(even) {
                    background-color: #f0feec !important;
                    td {
                        background-color: #f0feec !important;
                    }
                }

                &:hover {
                    td {
                        background-color: #fff2d3 !important;
                    }
                }
            }
        }

        // 修复固定列的补丁样式
        .el-table__fixed-right-patch {
            background-color: #f5f7fa !important;
        }

        // 处理 Element UI 的 hover-row 类
        .el-table__body tr.hover-row > td.el-table__cell,
        .el-table__fixed-body-wrapper tr.hover-row > td.el-table__cell {
            background-color: #fff2d3 !important;
        }

        // 处理固定列的边框
        .el-table__fixed-right,
        .el-table__fixed {
            .el-table__fixed-body-wrapper {
                .el-table__cell {
                    border-bottom: 1px solid #cacaca !important;
                }
            }
        }
    }
}

// 修改固定列的底边颜色
/deep/ .el-table {
    .el-table__fixed-right {
        .el-table__fixed-body-wrapper {
            .el-table__cell {
                border-bottom: 1px solid #cacaca !important;
            }
        }
    }
}

// 如果需要同时修改左侧固定列（如果有的话）
/deep/ .el-table {
    .el-table__fixed {
        .el-table__fixed-body-wrapper {
            .el-table__cell {
                border-bottom: 1px solid #cacaca !important;
            }
        }
    }
}
.round{
    color: #409eff;
    font-weight: bold;
    font-size: 16px;
    margin: 0 4px;
}
</style>
